import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
//import { throwError } from 'rxjs';
//import { catchError, map } from 'rxjs/operators';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
	
  	key = localStorage.getItem("key");
	  //public REST_API_SERVER = "http://localhost/ycdc-api/frontend/web/?r="; //Dev
    //public REST_API_SERVER = "https://ycdc-test-api.metanoa.ai/?r="; //Staging
	  public REST_API_SERVER = "https://ycdc-api.metanoa.ai/?r="; //Prod

    //private HT_API = "http://localhost/ht-api/frontend/web/?r=";
    private HT_API = "https://ht-api.metanoa.ai/?r="
    private HT_API_V2 = "https://v2.ht-api.metanoa.ai/?r="

    private FRESHDESK_API = "https://metanoa.freshdesk.com/api/v2/";

    public localData = {
      orgId:"",
      userId: (localStorage.getItem('user_id') !== null) ? localStorage.getItem('user_id') : "",
      roleId: (localStorage.getItem('role_id') !== null) ? localStorage.getItem('role_id') : "",
      billing: (localStorage.getItem('billing') !== null) ? localStorage.getItem('billing') : 1,
      gstBilling: (localStorage.getItem('gstBilling') !== null) ? localStorage.getItem('gstBilling') : 0,
      //gstBilling:1,
      report_generator:0,
      billContents:[],
      dateDashboard:"", 
      dateDashboardReception:"", 
      notesVisible:1,
      homeDate:"",
      showSubscriptionPop:true,
      isNabidh:0,
      patientMode:"",
      dateSchedule:"", 
      deptSchedule:0,
      deptScheduleName:"",
      selectedTherapistForBilling:0,
      branch: (localStorage.getItem('branch') !== null) ? JSON.parse(localStorage.getItem('branch')) : {id:0, name:""},
      lastDocId:"",
      gpbSupplier: null
    };

    public orgMeta = {
      patients: "Patients",
      patients_sc: "patients",
      patient: "Patient",
      patient_sc: "patient",
      is_school:true,
    };

    public edPatient = {
      patient_id:0,
      op_number:"",
      patient_name:"",
      phone:"",
      phone2:"",
      gender:"",
      dob:"",
      member_since:"",
      orgId:0,
      address:"",
      city:"",
      po:"",
      state:"",
      pin:"",
      country:"",
      nationality:"",
      nationality_nabidh:"",
      employment:"",
      monthly_income:"",
      languages:[],
      guardian:"",
      guardian_contact:"",
      id_type:"",
      id_number:"",
      referred_by:"",
      referral_reason:"",
      recommendations:"",
      class_std:""

    };

    
   
  

  constructor(private httpClient: HttpClient) { }

    

    public headBar() {
      return this.httpClient.get(this.REST_API_SERVER + "account/headbar", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

  	public getTherapistDashboard(date, mode=0, limit=30){
  		return this.httpClient.get(this.REST_API_SERVER + "therapist/dashboard&date="+date+"&mode="+mode+"&limit="+limit, {
  			headers: { 'key': localStorage.getItem("key") }
  		});
  	}

    public getPatientTags(){
  		return this.httpClient.get(this.REST_API_SERVER + "therapist/patient_tags", {
  			headers: { 'key': localStorage.getItem("key") }
  		});
  	}

    public getReception(limit, department, branch, date, search){
      return this.httpClient.get(this.REST_API_SERVER + "reception/dashboard"+"&limit="+limit+"&department="+department+"&branch="+branch+"&date="+date+"&search="+search , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }
    public getReceptionRecent(limit, date, branch=0){
      return this.httpClient.get(this.REST_API_SERVER + "reception/recent&limit="+limit+"&date="+date+"&branch="+branch, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getSchedules(branch){
      return this.httpClient.get(this.REST_API_SERVER + "reception/schedules&branch="+branch, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public loadReschedule(appointment_id){
      return this.httpClient.get(this.REST_API_SERVER + "reception/get_patient_by_appointment&appointment_id="+appointment_id, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getDate(date, dept_id, custom, cancelView, branch){
      return this.httpClient.get(this.REST_API_SERVER + "reception/schedules&selected="+date+"&dept="+dept_id+"&custom_date="+custom+"&cancel_view="+cancelView+"&branch="+branch , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }
    public getAppointments(date, user_id, cancelView){
      return this.httpClient.get(this.REST_API_SERVER + "reception/doc_appointments&date="+date+"&user_id="+user_id+"&cancel_view="+cancelView , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getAppointmentsFlex(date, dept_id, cancelView, branch){
      return this.httpClient.get(this.REST_API_SERVER + "reception/flex_appointments&date="+date+"&dept_id="+dept_id+"&cancel_view="+cancelView+"&branch="+branch , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getIndividualSchedule(date, doc_id, cancelView){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/schedules&selected="+date+"&doc_id="+doc_id+"&cancel_view="+cancelView , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getPatient(id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/get_patient&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public getUpcoming(id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/upcoming&appointment_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public getGroupAppointments(id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/group_appointments&appointment_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public getVisitDetails(id){
      return this.httpClient.get(this.REST_API_SERVER + "reception/visit_details&appointment_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getViewSlot(slot_id, therapist_id, date, selected){
      return this.httpClient.get(this.REST_API_SERVER + "reception/view_slot&slot_id="+slot_id+"&therapist_id="+therapist_id+"&date="+date+"&selected="+selected , {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getViewVisit(appointment_id){
      return this.httpClient.get(this.REST_API_SERVER + "reception/view_visit&appointment_id="+appointment_id , {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getViewPatientVisits(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "reception/view_visits_by_patient&patient_id="+patient_id , {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getBillingAppointment(appointment_id){
      return this.httpClient.get(this.REST_API_SERVER + "reception/billing_appointment&appointment_id="+appointment_id , {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

  	public getPatientProfile(id){
  		return this.httpClient.get(this.REST_API_SERVER + "patient/profile&patient_id="+id, {
  			headers: { 'key': localStorage.getItem("key") }
  		});
  	}

    public getPatientFollowups(id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/followups&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUserInfo(id){
      return this.httpClient.get(this.REST_API_SERVER + "admin/user_info&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientOverview(id, department) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/overview&patient_id="+id+"&department_id="+department, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }
    
    public getPatientFiles(id, flag="") {
      return this.httpClient.get(this.REST_API_SERVER + "patient/files&patient_id="+id+"&flag="+flag, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }
    public getDocumentPages(id) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/doc_pages&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getOpenFile(key) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/open_file&key="+key, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getOpenFormFile(key) {
      return this.httpClient.get(this.REST_API_SERVER + "therapist/open_form_file&key="+key, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getPatients(count, search, department, branch){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/patients&limit="+count+"&search="+search+"&department="+department+"&branch="+branch  , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getPatientsV2(count, search, department, branch, showAfter){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/load_patients&limit="+count+"&search="+search+"&department="+department+"&branch="+branch+"&show_after="+showAfter  , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getDocPatients(count, search, department, branch, showAfter=0){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/my_patients&limit="+count+"&search="+search+"&department="+department+"&branch="+branch+"&show_after="+showAfter  , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getPatientsReferred(limit, search, showAfter){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/patients_referred&limit="+limit+"&search="+search+"&show_after="+showAfter , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getSymptoms(id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/symptoms&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getTbCrf(id, page){
      return this.httpClient.get(this.REST_API_SERVER + "tb/load_tbc&patient_id="+id+"&page="+page, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getEnquiries(count, search, branch){
      return this.httpClient.get(this.REST_API_SERVER + "reception/enquiries&limit="+count+"&search="+search+"&branch="+branch , {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getEnquiryCategories(){
      return this.httpClient.get(this.REST_API_SERVER + "reception/enquiry_categories" , {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getClinics(search){
      return this.httpClient.get(this.REST_API_SERVER + "admin/clinics&search="+search, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getAssets(count, search){
      return this.httpClient.get(this.REST_API_SERVER + "admin/assets&limit="+count+"&search="+search, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLogoData(){
      return this.httpClient.get(this.REST_API_SERVER + "reception/get_logo", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getBills(count, search, mode, branch, date1, date2, billType, myBills){
      return this.httpClient.get(this.REST_API_SERVER + "reception/bills&limit="+count+"&search="+search+"&mode="+mode+"&branch="+branch+"&date1="+date1+"&date2="+date2+"&bill_type="+billType+"&myBills="+myBills, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientBills(patient_id, payment_status){
      return this.httpClient.get(this.REST_API_SERVER + "patient/bills&patient_id="+patient_id+"&payment_status="+payment_status, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPharmacyBills(count, search, search2, mode, branch, date1, date2, patient_id, bill_type=2, myBills){
      return this.httpClient.get(this.REST_API_SERVER + "pharmacy/bills&limit="+count+"&search="+search+"&search2="+search2+"&mode="+mode+"&branch="+branch+"&date1="+date1+"&date2="+date2+"&patient_id="+patient_id+"&bill_type="+bill_type+"&myBills="+myBills, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getBill(bill_id){
      return this.httpClient.get(this.REST_API_SERVER + "reception/bill&bill_id="+bill_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSingleBill(id){
      return this.httpClient.get(this.REST_API_SERVER + "reception/view_bill&bill_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDiscountPresets(){
      return this.httpClient.get(this.REST_API_SERVER + "billing/discount_presets", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getEvents(limit, mode, branch){
      return this.httpClient.get(this.REST_API_SERVER + "reception/events&limit="+limit+"&mode="+mode+"&branch="+branch, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getFeedbacks(limit){
      return this.httpClient.get(this.REST_API_SERVER + "admin/feedbacks&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUserProfile(){
      return this.httpClient.get(this.REST_API_SERVER + "account/profile", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUserLeaves(){
      return this.httpClient.get(this.REST_API_SERVER + "account/leaves", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDepts(viewAll=1, isSchedule=1){
      return this.httpClient.get(this.REST_API_SERVER + "reception/list_departments&viewAll="+viewAll+"&isSchedule="+isSchedule, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDepts2(){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/list_departments", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getTherapists(branch, show_all=0, dept=0){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/list&branch="+branch+"&show_all="+show_all+"&dept="+dept, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSlots(date, therapist){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/get_free_slots&date="+date+"&therapist="+therapist, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSubscriptionStatus(){
      return this.httpClient.get(this.REST_API_SERVER + "account/subscription_status", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getNotibar(){
      return this.httpClient.get(this.REST_API_SERVER + "account/notifications_bar", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getMenu(){
      return this.httpClient.get(this.REST_API_SERVER + "account/menu", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getOrg(branch){
      return this.httpClient.get(this.REST_API_SERVER + "account/get_org&branch="+branch, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getModules(user_id){
      return this.httpClient.get(this.REST_API_SERVER + "admin/user_modules&user_id="+user_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getNextPatientId(){
      return this.httpClient.get(this.REST_API_SERVER + "reception/next_patient_id", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getNotifications(limit, search, tag, date){
      return this.httpClient.get(this.REST_API_SERVER + "account/notifications&limit="+limit+"&search="+search+"&tag="+tag+"&date="+date, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getConsultations(date, department){
      return this.httpClient.get(this.REST_API_SERVER + "reception/consultations&date="+date+"&department="+department , {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUser(){
      return this.httpClient.get(this.REST_API_SERVER + "account/user", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUsers(limit, search, viewMode=0){
      return this.httpClient.get(this.REST_API_SERVER + "admin/users&limit="+limit+"&search="+search+"&viewMode="+viewMode, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getBillingUsers(){
      return this.httpClient.get(this.REST_API_SERVER + "admin/billing_users", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getRecentUpdates(limit, date, date2){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/recent_updates&limit="+limit+"&date="+date+"&date2="+date2, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSymTag(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/save_sym_tag", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeviceTag(data) {
      return this.httpClient.post(this.REST_API_SERVER + "service/save_device_tag", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeletePatientTag(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/delete_tag", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAssignMetTherapist(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/assign_patient", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAssignMetExTherapist(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/assign_existing_patient", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postClearNotifications(){
      return this.httpClient.get(this.REST_API_SERVER + "account/clear_notifications", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSaveTbcForm(data){
      return this.httpClient.post(this.REST_API_SERVER + "tb/save_tbc", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postNotes(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/add_note", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditNotes(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/edit_note", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postConsultation(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_consultation", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postCancelConsultation(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/cancel_consultation", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postMedications(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/prescribe_medications", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDevices(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/prescribe_devices", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postProsthetics(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/prescribe_prosthetics", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postTests(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/prescribe_tests", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postRefer(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/refer_branch", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDischarge(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/discharge", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postFile(data) {
      return this.httpClient.post(this.REST_API_SERVER + "client/upload", data, {
        headers: { 'key': localStorage.getItem("key") },
        reportProgress: true,
      });
    }

    public postServiceFile(data) {
      return this.httpClient.post(this.REST_API_SERVER + "service/upload", data, {
        headers: { 'key': localStorage.getItem("key") },
        reportProgress: true,
      });
    }

    public postFileForm(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/upload_form_file", data, {
        headers: { 'key': localStorage.getItem("key") },
        reportProgress: true,
      });
    }

    public postEditFile(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/edit_file", data, {
        headers: { 'key': localStorage.getItem("key") }
        
      });
    }

    public postDeleteFile(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_file", data, {
        headers: { 'key': localStorage.getItem("key") }
        
      });
    }

    public postClientDeleteFile(data) {
      return this.httpClient.post(this.REST_API_SERVER + "client/delete_file", data, {
        headers: { 'key': localStorage.getItem("key") }
        
      });
    }

    public postReportIssue(data) {
      return this.httpClient.post(this.REST_API_SERVER + "account/report_issue", data, {
        headers: { 'key': localStorage.getItem("key") },
        reportProgress: true,
      });
    }

    public postCreateTicket(data) {
      return this.httpClient.post(this.REST_API_SERVER + "account/create_ticket", data, {
        headers: { 'key': localStorage.getItem("key") },
        reportProgress: true,
      });
    }

    public postProfile(data) {
      return this.httpClient.post(this.REST_API_SERVER + "account/edit_profile", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postBill(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/new_bill", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postBillComplementaryStatus(data){
      return this.httpClient.post(this.REST_API_SERVER + "billing/update_complementary_status", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postUpdateBillDate(data){
      return this.httpClient.post(this.REST_API_SERVER + "billing/update_bill_date", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postBillPresetDiscount(data){
      return this.httpClient.post(this.REST_API_SERVER + "billing/apply_preset_discount", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postPharmacyBill(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/new_bill", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postCancelBill(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/cancel_bill", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSale(data){
      return this.httpClient.post(this.REST_API_SERVER + "service/new_sale", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditSale(data){
      return this.httpClient.post(this.REST_API_SERVER + "service/edit_sale", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public endSession(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/end_session", data, {
        headers: { 'key': localStorage.getItem("key") }
      })
    }

    public startSession(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/start_session", data, {
        headers: { 'key': localStorage.getItem("key") }
      })
    }

    public postRegistration(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/register", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAppointment(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_appointment", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAppointmentFlex(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_appointment_flex", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEnquiry(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_enquiry", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEvent(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_event", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }
    public postSlotNote(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_slotnote", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEnquiryAssignment(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/assign_enquiry", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postPassword(data) {
      return this.httpClient.post(this.REST_API_SERVER + "account/update_password", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postLeave(data) {
      return this.httpClient.post(this.REST_API_SERVER + "account/mark_leave", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postLeaveApplication(data) {
      return this.httpClient.post(this.REST_API_SERVER + "admin/apply_leave", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getUserLeaveApplications(){
      return this.httpClient.get(this.REST_API_SERVER + "account/leave_applications", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteLa(data){
      return this.httpClient.post(this.REST_API_SERVER + "account/delete_la", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLeaveApplications(status=0,show_after=0, user=0){
      return this.httpClient.get(this.REST_API_SERVER + "admin/leave_applications&status="+status+"&show_after="+show_after+"&user="+user, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postUpdateLaStatus(data){
      return this.httpClient.post(this.REST_API_SERVER + "admin/update_la_status", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public cancelAppointment(type, appointment_id) {
      var data = {"type":type, "appointment_id":appointment_id};
      return this.httpClient.post(this.REST_API_SERVER + "reception/cancel", data,  {
        headers: { 'key': localStorage.getItem("key") }
      } ); 
    }

    public postReschedule(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/reschedule", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postRescheduleFlex(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/reschedule_flex", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkBillPaid(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/mark_bill_paid", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUpdateBillDiscount(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/update_bill_discount", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUpdateBillSuggestedDiscount(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/update_bill_suggested_discount", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postBillIgnoreSuggestedDiscount(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/ignore_bill_suggested_discount", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postBillApplySuggestedDiscount(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/apply_bill_suggested_discount", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteEnquiry(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/delete_enquiry", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteNotification(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "account/delete_notification", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteEvent(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/delete_event", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkPresent(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/mark_present", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkDischarge(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/mark_discharge", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkComplete(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/mark_complete", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkConsultation(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/mark_consultation", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUndoCompleted(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/undo_completed", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }
    public postMarkAbsent(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/mark_absent", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUndoAbsent(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/undo_absent", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postClearSlot(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/clear_slot", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkHoliday(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/mark_holiday", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkLeave(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/mark_leave", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteSlotNote(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/delete_slot_note", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postSymptoms(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_symptoms", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUpdateSymptoms(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "patient/update_symptoms", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postCancelAppointment(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/cancel", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEditPatient(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "patient/edit", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAudioTest(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "patient/audio_test", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkRead(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "account/mark_read", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postRestoreUser(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/restore_user", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteUser(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/delete_user", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteDept(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/delete_dept", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddModule(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/add_module", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postRemoveModule(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/remove_module", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postNewUser(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/add_user", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUpdateUser(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/edit_user", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postNewDept(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/add_department", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }
    public postUpdateDept(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/edit_department", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    /* service registry, inventory and stuff */

    public getRegistry(limit, search, branch=0){
      return this.httpClient.get(this.REST_API_SERVER + "service/service_registry&limit="+limit+"&search="+search+"&branch="+branch, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getEstimate(service_id){
      return this.httpClient.get(this.REST_API_SERVER + "service/estimate&service_id="+service_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postMarkServiceComplete(data) {
      return this.httpClient.post(this.REST_API_SERVER + "service/mark_complete", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postMarkServiceReturned(data) {
      return this.httpClient.post(this.REST_API_SERVER + "service/mark_returned", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteService(data) {
      return this.httpClient.post(this.REST_API_SERVER + "service/delete", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public postAddService(data) {
      return this.httpClient.post(this.REST_API_SERVER + "service/add_entry", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postServiceStatus(data) {
      return this.httpClient.post(this.REST_API_SERVER + "service/edit_status", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postServiceEstimate(data) {
      return this.httpClient.post(this.REST_API_SERVER + "service/edit_estimate", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddAsset(data) {
      return this.httpClient.post(this.REST_API_SERVER + "admin/add_asset", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    

    public postEditAsset(data) {
      return this.httpClient.post(this.REST_API_SERVER + "admin/edit_asset", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteAsset(data) {
      return this.httpClient.post(this.REST_API_SERVER + "admin/delete_asset", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeletePo(data) {
      return this.httpClient.post(this.REST_API_SERVER + "service/delete_po", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postPo(data) {
      return this.httpClient.post(this.REST_API_SERVER + "service/add_po", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDelivery(data) {
      return this.httpClient.post(this.REST_API_SERVER + "service/add_delivery", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddFollowup(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/add_followup", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteFollowup(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_followup", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getInventory(limit, search, branch, tag){
      return this.httpClient.get(this.REST_API_SERVER + "service/inventory&limit="+limit+"&search="+search+"&branch="+branch+"&tag="+tag, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public postDeleteStock(data) {
      return this.httpClient.post(this.REST_API_SERVER + "service/delete_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public postMoveStock(data) {
      return this.httpClient.post(this.REST_API_SERVER + "service/move_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getBranches(){
      return this.httpClient.get(this.REST_API_SERVER + "service/branches", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getBranchesList(limit, search){
      return this.httpClient.get(this.REST_API_SERVER + "admin/list_branches&limit="+limit+"&search="+search, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddBranch(data) {
      return this.httpClient.post(this.REST_API_SERVER + "admin/add_branch", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditBranch(data) {
      return this.httpClient.post(this.REST_API_SERVER + "admin/edit_branch", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteBranch(data) {
      return this.httpClient.post(this.REST_API_SERVER + "admin/delete_branch", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPos(limit, search, date){
      return this.httpClient.get(this.REST_API_SERVER + "service/purchase_orders&limit="+limit+"&search="+search+"&date="+date, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPo(id){
      return this.httpClient.get(this.REST_API_SERVER + "service/view_po&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDeliveries(id){
      return this.httpClient.get(this.REST_API_SERVER + "service/purchase_deliveries&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPoHistory(limit, date1, date2, search){
      return this.httpClient.get(this.REST_API_SERVER + "service/purchase_history&limit="+limit+"&date1="+date1+"&date2="+date2+"&search="+search  , {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddStock(data) {
      return this.httpClient.post(this.REST_API_SERVER + "service/add_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditStock(data) {
      return this.httpClient.post(this.REST_API_SERVER + "service/edit_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSales(limit, search, branch, date1, date2){
      return this.httpClient.get(this.REST_API_SERVER + "service/sales&limit="+limit+"&search="+search+"&branch="+branch+"&date1="+date1+"&date2="+date2, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLog(limit, date1, date2, search){
      
      return this.httpClient.get(this.REST_API_SERVER + "service/transfer_log&limit="+limit+"&date1="+date1+"&date2="+date2+"&search="+search , {
        headers: { 'key': localStorage.getItem("key") }
      });
    }


    /* homeprogram */

    public getChat(id){
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/chat_thread_therapist&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtActivities(id){
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/ht_activities&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGoal(id){
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/view_goal&goal="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDailyTasks(id){
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/daily_tasks&goal="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHowto(id){
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/goal_howto&goal="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getTutorials(id){
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/ht_tutorials&goal_id="+id, {

        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUploads(id){
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/ht_uploads&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getVideo(video_id) {
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/get_file&video_id="+video_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

     public postSendChat(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "hometherapy/send_message_therapist", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAssignGoal(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "hometherapy/assign_goal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkActComplete(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "hometherapy/mark_act_complete", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUpload(data) {
      return this.httpClient.post(this.REST_API_SERVER + "hometherapy/tutorial_upload", data, {
          headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGoalLibrary(limit, search){
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/goal_library&limit="+limit+"&search="+search, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddGoalLib(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_goal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEditGoalLib(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/edit_goal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteGoalLib(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/delete_goal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddHowto(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "hometherapy/add_howto", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteHowto(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "hometherapy/delete_howto", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postPatientFeedback(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "patient/feedback", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }


    //Assessment forms

    public getAssessmentForms(){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/assessment_forms", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getAssessmentForm(form_id, patient_id, submission_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/assessment_form&form_id="+form_id+"&patient_id="+patient_id+"&submission_id="+submission_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postNewAssessmentForm(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/new_assessment_form", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAssessmentQuestion(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/new_assessment_question", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteAssessmentQuestion(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/delete_assessment_question", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public postAssessmentHeading(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/new_assessment_heading", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSaveAssessmentForm(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/save_assessment_form", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public postSurveyResponse(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/save_survey_response", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getResponseTypes(){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/response_types", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientSubmissions(patient, date, limit){
      return this.httpClient.get(this.HT_API + "forms/submissions&patient="+patient+"&date="+date+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientAdl(ht_id){
      return this.httpClient.get(this.HT_API + "forms/patient_submissions&ht_id="+ht_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientFormSubmission(formId){
      return this.httpClient.get(this.HT_API + "forms/submission&id="+formId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postChIndex(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_index", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChIndex(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_index&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChInfancy(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_infancy&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChAdditional(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_additional&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChAnnexe(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_annexe&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postChInfancy(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_infancy", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postChAdditional(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_additional", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postChAnnexe(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_annexe", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postChMedicalHistory(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_medical_history", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChMedicalHistory(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_medical_history&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postChLanguage(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_language", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChLanguage(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_language&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postChSocioFamily(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_socio_family", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChSocioFamily(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_socio_family&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postChRegistration(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_registration", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChRegistration(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_registration&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postChInformation(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_information", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChInformation(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_information&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postChFamHistory(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_family_history", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChFamHistory(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_family_history&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postChBirthHistory(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_birth_history", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChBirthHistory(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_birth_history&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postChMedHistory(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_med_history", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChMedHistory(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_med_history&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postChBHistory(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_b_history", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChBHistory(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_b_history&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postChEduHistory(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_edu_history", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChEduHistory(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_edu_history&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }


    public getChNavigation(url){
      return this.httpClient.get(this.REST_API_SERVER + "forms/ch_forms&url_string="+url+"&v2=true", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getCdiForm(formId, patientId){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_cdi_form&form_id="+formId+"&patient_id="+patientId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getCdiResult(patientId){
      return this.httpClient.get(this.REST_API_SERVER + "forms/cdi_result&patient_id="+patientId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postCdiResponse(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_cdi_response", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postCdiReport(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_cdi_report", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getIdeForm(formId, patientId){
      return this.httpClient.get(this.REST_API_SERVER + "forms/load_ide&form_id="+formId+"&patient_id="+patientId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postIdeResponse(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_ide_response", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getIdeResult(patientId){
      return this.httpClient.get(this.REST_API_SERVER + "forms/ide_results&patient_id="+patientId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLessonPlan(patientId, visitId, offset){
      return this.httpClient.get(this.REST_API_SERVER + "forms/lesson_plan&patient_id="+patientId+"&visit_id="+visitId+"&offset="+offset, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postLessonPlan(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_lesson_plan", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSessionReports(patientId){
      return this.httpClient.get(this.REST_API_SERVER + "forms/session_reports&patient_id="+patientId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSessionReport(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_session_report", data, {
          headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteSessionReport(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/delete_session_report", data, {
          headers: { 'key': localStorage.getItem("key") }
      });
    }

    /*------------------------------*/

    public login(data) {
      return this.httpClient.post(this.REST_API_SERVER + "account/login", data);
    }

    public signup(data) {
      return this.httpClient.post(this.REST_API_SERVER + "account/signup", data);
    }

    public postEnableAccount(data) {
      return this.httpClient.post(this.REST_API_SERVER + "admin/enable_account", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }


    //statistics

    public getStatOverview(val){
      return this.httpClient.get(this.REST_API_SERVER + "admin/stats_overview&department="+val, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getStatRevenue(period, dept, category){
      return this.httpClient.get(this.REST_API_SERVER + "admin/revenue&period="+period+"&dept="+dept+"&category="+category, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }



    /* resource lib and homeprogram */

    public getGoals(level, category){
      return this.httpClient.get(this.REST_API_SERVER + "goals/library&level="+level+"&category="+category, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGoalStats(){
      return this.httpClient.get(this.REST_API_SERVER + "goals/stats", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSubGoals(goal_id){
      return this.httpClient.get(this.REST_API_SERVER + "goals/subgoals&goal_id="+goal_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    

    public getGoalCategories(){
      return this.httpClient.get(this.REST_API_SERVER + "goals/categories", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGoalLevels(){
      return this.httpClient.get(this.REST_API_SERVER + "goals/levels", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSteps(id){
      return this.httpClient.get(this.REST_API_SERVER + "goals/goal_steps&goal_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getRequirements(id){
      return this.httpClient.get(this.REST_API_SERVER + "goals/goal_requirements&goal_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddGoalTask(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_task", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddGoalLevel() {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_level", {}, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddGoalCategory(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_category", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddSubgoal(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_subgoal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddActivity(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_activity", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddStep(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_step", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteStep(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/delete_step", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteReq(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/delete_requirement", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddReq(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_requirement", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getPatientHt(id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/get_ht&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGaActivities(goal_id, patient_id=0){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/ga_activities&goal_id="+goal_id+"&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    

    public getHtPatientGoals(phone, limit){
      return this.httpClient.get(this.HT_API + "therapist/patient_goals&phone="+phone+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtPatientActivities(goal_id){
      return this.httpClient.get(this.HT_API + "therapist/goal_activities&goal_id="+goal_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtGoalUploads(goal_id){
      return this.httpClient.get(this.HT_API + "therapist/goal_uploads&goal_id="+goal_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtGoalLevels(){
      return this.httpClient.get(this.HT_API + "therapist/goal_levels", {
        headers: { 'key': localStorage.getItem("key") }
      }); 
    }

    public getHtGoalCategories(){
      return this.httpClient.get(this.HT_API + "therapist/goal_categories", {
        headers: { 'key': localStorage.getItem("key") }
      }); 
    }

    public getHtLibraryGoals(level, category){
      return this.httpClient.get(this.HT_API + "therapist/library_goals&level="+level+"&category="+category, {
        headers: { 'key': localStorage.getItem("key") }
      }); 
    }

    public postHtAssignGoals(data){
      return this.httpClient.post(this.HT_API + "therapist/assign_goals", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postHtSendPush(ids){
      let data = {'goals': ids};
      return this.httpClient.post(this.HT_API + "therapist/send_push", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postHtSendPushGoalComplete(id){
      let data = {'goal_id': id};
      return this.httpClient.post(this.HT_API + "therapist/send_push_goal_complete", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postHtMarkGoal(data){
      return this.httpClient.post(this.HT_API + "therapist/mark_goal", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtPatientUploads(goal_id, phone, offset){
      return this.httpClient.get(this.HT_API + "therapist/patient_uploads&goal_id="+goal_id+"&phone="+phone+"&offset="+offset, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtGoalTutorials(goal_id, offset){

      return this.httpClient.get(this.HT_API + "therapist/goal_tutorials&goal_id="+goal_id+"&offset="+offset, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postHtSymptoms(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_symptoms_ht", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtPatientOverview(id) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/ht_overview&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getDeviceTags(){
      return this.httpClient.get(this.REST_API_SERVER + "service/device_tags", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }


    /* ht */

    public postChatFile(data) {
      return this.httpClient.post(this.HT_API + "therapist/upload_chat_file", data, {
        headers: { 'key': localStorage.getItem("key") },
        reportProgress: true,
      });
    }

    public postCallEnd(data) {
      return this.httpClient.post(this.HT_API + "chat/push", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public validateUser() {
      return this.httpClient.get(this.HT_API + "chat/validate_user", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHometherapy(phone){
      return this.httpClient.get(this.HT_API + "hometherapy/check_patient&phone="+phone, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChatRoom(phone){
      return this.httpClient.get(this.HT_API + "hometherapy/get_chatroom&phone="+phone, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChatHistory(roomId){
      return this.httpClient.get(this.HT_API + "chat/history_web&room_id="+roomId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getMoreChatHistory(roomId, chatId){
      return this.httpClient.get(this.HT_API + "chat/history_web_more&room_id="+roomId+"&chat_id="+chatId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSender(){
      return this.httpClient.get(this.HT_API + "hometherapy/get_sender", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getCallingPatient(id){
      return this.httpClient.get(this.HT_API + "hometherapy/get_calling_patient&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getRecipient(phone){
      return this.httpClient.get(this.HT_API + "hometherapy/get_recipient&phone="+phone, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }


    /* Internal resource library */

    public getRlibCategories(){
      return this.httpClient.get(this.HT_API + "resource/categories", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddRlibCategory(data) {
      
      return this.httpClient.post(this.HT_API + "resource/add_category", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getRlibLevels(){
      return this.httpClient.get(this.HT_API + "resource/levels", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddRlibLevel() {
      
      return this.httpClient.post(this.HT_API + "resource/add_level", {}, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getRlibAgegroups(){
      return this.httpClient.get(this.HT_API + "resource/age_groups", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddRlibAg(data) {
      return this.httpClient.post(this.HT_API + "resource/add_ag", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postAddRlibDisorder(data) {
      return this.httpClient.post(this.HT_API + "resource/add_disorder", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public getRlibDisorders(){
      return this.httpClient.get(this.HT_API + "resource/disorders", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getRlibGoals(category, disorder, agegroup){
      return this.httpClient.get(this.HT_API + "resource/goals&category="+category+"&disorder="+disorder+"&agegroup="+agegroup, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddRlibGoal(data) {
      
      return this.httpClient.post(this.HT_API + "resource/add_goal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getRlibActivities(goal_id){
      return this.httpClient.get(this.HT_API + "resource/activities&goal_id="+goal_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddRlibActivity(data) {
      
      return this.httpClient.post(this.HT_API + "resource/add_activity", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddRlibTask(data) {
      
      return this.httpClient.post(this.HT_API + "resource/add_task", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getRlibSteps(id){
      return this.httpClient.get(this.HT_API + "resource/goal_steps&goal_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getRlibRequirements(id){
      return this.httpClient.get(this.HT_API + "resource/goal_requirements&goal_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddRlibStep(data) {
      
      return this.httpClient.post(this.HT_API + "resource/add_step", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteRlibStep(data) {
      
      return this.httpClient.post(this.HT_API + "resource/delete_step", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteRlibReq(data) {
      
      return this.httpClient.post(this.HT_API + "resource/delete_requirement", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddRlibReq(data) {
      
      return this.httpClient.post(this.HT_API + "resource/add_requirement", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddRlibTags(data) {
      
      return this.httpClient.post(this.HT_API + "resource/add_tags", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteRlibTags(data) {
      
      return this.httpClient.post(this.HT_API + "resource/delete_tags", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getRlibTags(id){
      return this.httpClient.get(this.HT_API + "resource/goal_tags&goal_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientAppointments(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "reception/patient_appointments&patient_id="+patient_id , {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getAnalyticsV2(period, branch, dept, consultant, date1,date2){
  		return this.httpClient.get(this.REST_API_SERVER + "analytics/v2&period="+period+"&branch="+branch+"&dept="+dept+"&consultant="+consultant+"&cdate1="+date1+"&cdate2="+date2, {
  			headers: { 'key': localStorage.getItem("key") }
  		});
  	}

    public getAnalyticsBillData(period, branch, dept, consultant, date1,date2){
  		return this.httpClient.get(this.REST_API_SERVER + "analytics/bill_data&period="+period+"&branch="+branch+"&dept="+dept+"&consultant="+consultant+"&cdate1="+date1+"&cdate2="+date2, {
  			headers: { 'key': localStorage.getItem("key") }
  		});
  	}

    public getAnalyticsDemographics(period, branch, dept, consultant, date1,date2){
  		return this.httpClient.get(this.REST_API_SERVER + "analytics/demographics&period="+period+"&branch="+branch+"&dept="+dept+"&consultant="+consultant+"&cdate1="+date1+"&cdate2="+date2, {
  			headers: { 'key': localStorage.getItem("key") }
  		});
  	}

    public getAnalytics(period, date1, date2){
  		return this.httpClient.get(this.REST_API_SERVER + "analytics/therapist&period="+period+"&date1="+date1+"&date2="+date2, {
  			headers: { 'key': localStorage.getItem("key") }
  		});
  	}
    public getAnalyticsAdmin(period, date1, date2, therapist, dept, branch){
  		return this.httpClient.get(this.REST_API_SERVER + "analytics/admin&period="+period+"&date1="+date1+"&date2="+date2+"&therapist_id="+therapist+"&dept="+dept+"&branch="+branch, {
  			headers: { 'key': localStorage.getItem("key") }
  		});
  	}

    public getTherapistsAc(term, branch){
      return this.httpClient.get(this.REST_API_SERVER + "analytics/therapist_ac&term="+term+"&branch="+branch, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDeptAc(term, branch){
      return this.httpClient.get(this.REST_API_SERVER + "departments/autocomplete&term="+term+"&branch="+branch, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getFollowup(){
      return this.httpClient.get(this.REST_API_SERVER + "admin/get_followup", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteFsRule(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/delete_followup", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postSaveFsRule(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/save_followup", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postSaveCdiCoords(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_cdi_coords", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getCdiCoords(id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/get_cdi_coords&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientByPhone(phone){
      return this.httpClient.get(this.REST_API_SERVER + "patient/get_patient_by_phone&phone="+phone, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUnifiedForms(offset, patient_id, limit){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/unified_forms&offset="+offset+"&patient_id="+patient_id+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUnifiedForm(form_id, patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/unified_form&form_id="+form_id+"&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUnifiedCredentials(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/ufc&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSaveUnifiedForm(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/save_unified_form", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSignUnifiedForm(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/sign_unified_form", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSkipUnifiedForm(form_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/skip_unified_form&form_id="+form_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    
   

    /* home-program using internal resource lib */
    
    public getGoalsActivities(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/goals_activities&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getAssessmentGoalsActivities(patient_id, assessment_id, limit=0, dept_id=0){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/assessment_goals_activities&patient_id="+patient_id+"&assessment_id="+assessment_id+"&limit="+limit+"&dept_id="+dept_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGaAgegroups(){
      return this.httpClient.get(this.REST_API_SERVER + "resource/age_groups", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGaGoalLevels(){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/goal_levels", {
        headers: { 'key': localStorage.getItem("key") }
      }); 
    }

    public getGaGoalCategories(){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/goal_categories", {
        headers: { 'key': localStorage.getItem("key") }
      }); 
    }

    public getGaLibraryGoals(level, category, dept_id=0, mode=1){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/library_goals&level="+level+"&category="+category+"&dept_id="+dept_id+"&mode="+mode, {
        headers: { 'key': localStorage.getItem("key") }
      }); 
    }

    public getAssessmentGoals(assessment_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/library_assessment_goals&assessment_id="+assessment_id, {
        headers: { 'key': localStorage.getItem("key") }
      }); 
    }

    public postGaAssignGoals(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/assign_goals", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGaSubGoals(goal_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/subgoals&goal_id="+goal_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddGaTask(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/add_task", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEditGoalActivity(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/edit_task", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEditSubgoal(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/edit_subgoal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteSubgoal(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/delete_subgoal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteGoalActivity(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/delete_task", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddGaLevel() {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/add_ga_level", {}, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddGaCategory(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/add_ga_category", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddGaActivity(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/add_activity", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddGa(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/add_goal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getGaCategories(){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/goal_categories", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGaLevels(){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/goal_levels", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getActivityEv(activity, patient){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/load_activity_ev&activity_id="+activity+"&patient_id="+patient, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGoalEv_(goal, patient){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/load_goal_ev&goal_id="+goal+"&patient_id="+patient, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getTaskEv(task_id, assignment_id, patient){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/load_task_ev&task_id="+task_id+"&assignment_id="+assignment_id+"&patient_id="+patient, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGoalEv(assignment_id, patient){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/load_goal_ev&assignment_id="+assignment_id+"&patient_id="+patient, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSubgoalEv(assignment_id, subgoal_id, patient){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/load_subgoal_ev&assignment_id="+assignment_id+"&subgoal_id="+subgoal_id+"&patient_id="+patient, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGoalsProgress(patient, mode){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/goal_progress&mode="+ mode+"&patient_id="+patient, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGoalsProgressInd(patient, goal){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/subgraph_data&goal_id="+ goal+"&patient_id="+patient, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditGaGoal(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/edit_goal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteGaGoal(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/delete_goal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postGaEvaluation(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/mark_goal_trial", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postTaskEvaluation(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/mark_task_trial", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkTaskCompletion(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/mark_task_completion", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkGoalCompletion(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/mark_goal_completion", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkSubgoalCompletion(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/mark_subgoal_completion", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postGoalEvaluation(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/mark_goal_trial", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postSubgoalEvaluation(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/mark_subgoal_trial", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postChFile(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/ch_upload", data, {
        headers: { 'key': localStorage.getItem("key") },
        reportProgress: true,
      });
    }
    public getChFiles(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/ch_files&patient_id="+ patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSurveyForm(form_id, patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/survey_form&form_id="+form_id+"&patient_id="+ patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    

    public postSfResponse(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_survey_response", data, {
        headers: { 'key': localStorage.getItem("key") },
      });
    }

    public getSurveyScore(form_id,patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/survey_score&form_id="+form_id+"&patient_id="+ patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChecklist(form_id, patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/checklist&form_id="+form_id+"&patient_id="+ patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postClResponse(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_checklist_response", data, {
        headers: { 'key': localStorage.getItem("key") },
      });
    }
    public getTableForm(form_id, patient_id, submission_id=0){
      return this.httpClient.get(this.REST_API_SERVER + "forms/table_form&form_id="+form_id+"&patient_id="+ patient_id+"&submission_id="+submission_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postTfResponse(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_table_response", data, {
        headers: { 'key': localStorage.getItem("key") },
      });
    }

    public postGenerateFormSubmission(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/generate_form_submission", data, {
        headers: { 'key': localStorage.getItem("key") },
      });
    }

    public getMrRecord(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/mr_record&patient_id="+ patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getMrScore(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/mr_score&patient_id="+ patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postMrResponse(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_mr_response", data, {
        headers: { 'key': localStorage.getItem("key") },
      });
    }

    public getToolsForms(){
      return this.httpClient.get(this.REST_API_SERVER + "forms/tools_forms&v2=true", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDevAssessment(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/developmental_assessment&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDaResponse(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/save_da_response", data, {
        headers: { 'key': localStorage.getItem("key") },
      });
    }

    public postSelectParent(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/select_parent", data, {
        headers: { 'key': localStorage.getItem("key") },
      });
    }

    public postDeleteFormFile(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/delete_form_file", data, {
          headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteTableRow(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/delete_table_row", data, {
          headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getBillHeads(limit=0, offset=0){
      return this.httpClient.get(this.REST_API_SERVER + "reception/bill_heads&limit="+limit+"&offset="+offset, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getProcedureBillHeads(){
      return this.httpClient.get(this.REST_API_SERVER + "reception/bill_heads_procedure", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getBillTypes(){
      return this.httpClient.get(this.REST_API_SERVER + "billing/types", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getBillLabels(){
      return this.httpClient.get(this.REST_API_SERVER + "reception/bill_labels", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postBillHead(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_bill_head", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postEditBillHead(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/edit_bill_head", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postDeleteBillHead(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/delete_bill_head", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }


    public getPatientForms(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/forms&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSinglePatientForm(form_id, patient_id, submission_id=0){
      return this.httpClient.get(this.REST_API_SERVER + "patient/view_form&id="+form_id+"&patient_id="+patient_id+"&submission_id="+submission_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    
    public getSinglePatientFormProgress(form_id, patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/view_form_progress&id="+form_id+"&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSubmitFormResponse(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/submit_form_response", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public getFormResult(form_id, patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/form_result&form_id="+form_id+"&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSubmittedAssessments(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/submitted_assessments&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAssessmentReport(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/save_assessment_report", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public getAssessmentReport(patient_id, form_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/assessment_report&patient_id="+patient_id+"&form_id="+form_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }


    /* form builder */

    public postFbNewForm(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/new_form", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public getList_forms(){
      return this.httpClient.get(this.REST_API_SERVER + "forms/list_forms", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getF2Form(id, page_id=0, patient_id=0, submission_id=0, return_latest=0){
      return this.httpClient.get(this.REST_API_SERVER + "forms/get_f2_form&id="+id+"&page_id="+page_id+"&patient_id="+patient_id+"&submission_id="+submission_id+"&return_latest="+return_latest, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getF2FormSubmissions(form_id, patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/f2_form_submissions&form_id="+form_id+"&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postF2NewQuestion(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_add_question", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2UpdateQuestion(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_update_question", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2NewOption(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_add_option", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2ChangeQuestionOrder(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_change_question_order", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2DeleteQuestion(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_delete_question", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2DeleteOption(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_delete_option", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2DeleteForm(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_delete_form", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2DuplicateForm(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_duplicate_form", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2UpdateFormName(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_update_form_name", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2AddFormPage(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_add_form_page", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2DuplicateQuestion(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_duplicate_question", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2UpdateResponse(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_update_response", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2UpdateTableResponse(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_update_table_response", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2UpdateSurveyResponse(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_update_survey_response", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2UpdateTableQuestion(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_update_table_question", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2UpdateTableOption(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_update_table_option", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2AddTableQuestion(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_add_table_question", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2AddTableOption(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_add_table_option", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2DeleteTableQuestion(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_delete_table_question", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2DeleteTableOption(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_delete_table_option", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postF2UpdateTableOptionPreset(data) {
      return this.httpClient.post(this.REST_API_SERVER + "forms/f2_update_table_option_preset", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public getF2FormsPatient(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/list_forms_patient&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getReportTemplate(id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/report_template&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSetNotesVisibility(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/set_notes_visibility", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postShareWith(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/share_profile", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public getSharedWith(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/shared_with&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postStopSharing(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/stop_share_profile", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    /* v2 - patient profile etc. */

    public getClientProfile(id){
      return this.httpClient.get(this.REST_API_SERVER + "client/profile&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getClientOverview(id, dept, consultant, limit){
      return this.httpClient.get(this.REST_API_SERVER + "client/overview&patient_id="+id+"&department_id="+dept+"&consultant_id="+consultant+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getClientGoals(id, limit){
      return this.httpClient.get(this.REST_API_SERVER + "client/goals_activities&patient_id="+id+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getClientAssessments(id, limit){
      return this.httpClient.get(this.REST_API_SERVER + "client/assessments&patient_id="+id+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getClientFiles(id, limit, search){
      return this.httpClient.get(this.REST_API_SERVER + "client/files&patient_id="+id+"&limit="+limit+"&search="+search, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getClientAppointments(id, mode, limit){
      return this.httpClient.get(this.REST_API_SERVER + "client/appointments&patient_id="+id+"&mode="+mode+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getClientReferralStatus(id){
      return this.httpClient.get(this.REST_API_SERVER + "client/referral_status&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getClientRemarks(id){
      return this.httpClient.get(this.REST_API_SERVER + "client/view_remarks&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getClientAudioTest(id){
      return this.httpClient.get(this.REST_API_SERVER + "client/audio_test&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public getClientSd(id){
      return this.httpClient.get(this.REST_API_SERVER + "client/view_sd&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postClientSd(data) {
      return this.httpClient.post(this.REST_API_SERVER + "client/add_sd", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public postGenerateSdNote(data) {
      return this.httpClient.post(this.REST_API_SERVER + "client/generate_sd_note", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }

    public getClientReAssessment(id, form_id){
      return this.httpClient.get(this.REST_API_SERVER + "client/create_reassessment&patient_id="+id+"&form_id="+form_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    
    public postPfNotes(data) {
      return this.httpClient.post(this.REST_API_SERVER + "client/add_pf_notes", data, {
        headers: { 'key': localStorage.getItem("key") },
        
      });
    }
    
    public getGoalSteps(id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/goal_steps&goal_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getActivitySteps(id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/goal_activity_steps&activity_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGoalRequirements(id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/goal_requirements&goal_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddGoalStep(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/add_step", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddGoalActivityStep(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/add_activity_step", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddGoalMaterial(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/add_material", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddGoalActivityMaterial(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/add_activity_material", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteGoalStep(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/delete_step", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteGoalActivityStep(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/delete_activity_step", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postGaAssignGoalsWithId(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/assign_goals_with_id", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getReportGeneratorStatus(formid){
      return this.httpClient.get(this.REST_API_SERVER + "client/report_generator_status&form_id="+formid, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getFormReport(form_id, form_type, submission_id, patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "client/get_form_report&form_id="+form_id+"&form_type="+form_type+"&submission_id="+submission_id+"&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGeneratedReport(patient_id, formid, submission_id=0){
      return this.httpClient.get(this.REST_API_SERVER + "client/generate_report&patient_id="+patient_id+"&form_id="+formid+"&submission_id="+submission_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientSymptoms(id, formid){
      return this.httpClient.get(this.REST_API_SERVER + "client/load_patient_symptoms&patient_id="+id+"&form_id="+formid, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddPatientSymptom(data){
      return this.httpClient.post(this.REST_API_SERVER + "client/add_patient_symptom", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeletePatientSymptom(data){
      return this.httpClient.post(this.REST_API_SERVER + "client/delete_patient_symptom", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddPatientReport(data){
      return this.httpClient.post(this.REST_API_SERVER + "client/add_patient_report", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getReportHeaders(){
      return this.httpClient.get(this.REST_API_SERVER + "client/report_headers", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getReportSubHeaders(header_id, patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "client/report_subheaders&header_id="+header_id+"&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getReportSubHeader(header_id, patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "client/load_report_subheader&subheader_id="+header_id+"&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getOrgProfile(){
      return this.httpClient.get(this.REST_API_SERVER + "account/org_profile", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getOrgPayments(){
      return this.httpClient.get(this.REST_API_SERVER + "account/org_payments", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    
    public getGenerateDisposableToken(){
      return this.httpClient.get(this.REST_API_SERVER + "account/generate_disposable_token", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postReportPatientData(data){
      return this.httpClient.post(this.REST_API_SERVER + "client/save_report_patient_data", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postReportSelection(data){
      return this.httpClient.post(this.REST_API_SERVER + "client/select_report_section", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getReportFinal(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "client/report_final&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postMarkAttendance(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/mark_attendance", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeletePatient(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postUpdatePatientInternational(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/update_international_status", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postUpdatePatientVSSC(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/update_vssc_status", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public startTherapySession(appointment_id, mode){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/start_therapy_session&appointment_id="+appointment_id+"&mode="+mode, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getIncompleteRegistrations(limit=7){
      return this.httpClient.get(this.REST_API_SERVER + "reception/incomplete_registrations&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postIgnoreIncompleteRegistration(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/ignore_incomplete", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getBranchPatients(limit=5){
      return this.httpClient.get(this.REST_API_SERVER + "reception/branch_patients&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    

    public getSuperAnalytics(){
      return this.httpClient.get(this.REST_API_SERVER + "admin/super_analytics", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postNewSessions(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/confirm_new_sessions", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtV2OrgForms(){
      return this.httpClient.get(this.HT_API_V2 + "forms/org_forms", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtV2OrgFormSingle(form_id, patient_phone, patient_name, limit, show_after){
      return this.httpClient.get(this.HT_API_V2 + "forms/view_org_form&form_id="+form_id+"&patient_phone="+patient_phone+"&patient_name="+patient_name+"&limit="+limit+"&show_after="+show_after, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    getHtV2Bookings(visit_date){
      return this.httpClient.get(this.HT_API_V2 + "therapist/ht_bookings&visit_date="+visit_date, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }


    /* Freshdesk */

    getFreshDeskTickets(userId, orgId){
      let query = "cf_user_id:" + userId + " AND " + " cf_org_id:" + orgId;
      return this.httpClient.get(this.FRESHDESK_API + "search/tickets?query=\""+query+"\"", {
        headers: { 'Authorization': 'Basic eTFTemhDWlVCd3RDNGVhT2VZOTI6'  }
      });
    }

    getFreshDeskTicket(id){
      return this.httpClient.get(this.FRESHDESK_API + "tickets/"+id+"?include=conversations", {
        headers: { 'Authorization': 'Basic eTFTemhDWlVCd3RDNGVhT2VZOTI6'  }
      });
    }

    getFreshDeskTicketConversations(id){
      return this.httpClient.get(this.FRESHDESK_API + "tickets/"+id+"/conversations", {
        headers: { 'Authorization': 'Basic eTFTemhDWlVCd3RDNGVhT2VZOTI6'  }
      });
    }

    public postTicketReply(data, id){
      return this.httpClient.post(this.FRESHDESK_API + "tickets/"+id+"/reply", data, {
        headers: { 'Authorization': 'Basic eTFTemhDWlVCd3RDNGVhT2VZOTI6'  }
      });
    }

    /* Compass report */

    public getCrPage(page_id="", patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/compass_report&page_id="+page_id+"&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getCrPages(){
      return this.httpClient.get(this.REST_API_SERVER + "forms/cr_pages", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postCrResponses(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/cr_save_responses", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getCrFinal(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "forms/compass_report_final&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSaNewClinic(data){
      return this.httpClient.post(this.REST_API_SERVER + "super/add_clinic", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDenverCoords(patient_id, datestring) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/denver_coords&patient_id="+patient_id+"&datestring="+datestring, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDenverCoords(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_denver_coords", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postDeleteCanvasCoords(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_canvas_coords", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postDeleteLastDenver(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_denver_line", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postDenverComments(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_denver_comments", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public getDenverComments(patient_id, type) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/denver_comments&patient_id="+patient_id+"&type="+type, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteDenverComments(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_denver_comments", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postEditDenverComments(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/edit_denver_comments", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postLestData(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_lest", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public getLestData(patient_id, type, datestring) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/lest_data&patient_id="+patient_id+"&type="+type+"&datestring="+datestring, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteLastLest(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_last_lest", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postPbsAnswer(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_pbs_answer", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postPbsTextVal(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_pbs_text_val", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPbs(patient_id, submission_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/pediatric_balance_scale&patient_id="+patient_id+"&submission_id="+submission_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getMas(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/load_mas&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddMas(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_mas", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteMas(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_mas", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddTug(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_tug", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteTug(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_tug", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postTugAd(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_tug_ad", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getTug(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/load_tug&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getAsd(patient_id, submission_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/load_asd&patient_id="+patient_id+"&submission_id="+submission_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postUpdateAsd(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/update_asd", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getIndt(section_id, patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/indt&section_id="+section_id+"&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSaveIndt(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_indt", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSaveIndtComment(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_indt_comment", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGraphData( patient_id,graph_type){
      return this.httpClient.get(this.REST_API_SERVER +"patient/load_growth_chart&patient_id="+patient_id+"&graph_type="+graph_type, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postGraphData(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_gc_data",data,{
        headers: {'key': localStorage.getItem("key")}
      });
    }

    public postDeleteGraphData(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_gc_data",data,{
        headers: {'key': localStorage.getItem("key")}
      });
    }

    public getFormsLibrary(patient_id=0){
      return this.httpClient.get(this.REST_API_SERVER +"forms/library&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getFormsSelected(patient_id=0){
      return this.httpClient.get(this.REST_API_SERVER +"forms/selected&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSelectLibForm(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/select_library_form",data,{
        headers: {'key': localStorage.getItem("key")}
      });
    }

    public postSelectF2Form(data){
      return this.httpClient.post(this.REST_API_SERVER + "forms/select_f2_form",data,{
        headers: {'key': localStorage.getItem("key")}
      });
    }

    public getPatientFormSubmissions(patient_id){
      return this.httpClient.get(this.REST_API_SERVER +"forms/patient_submissions&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postUpdateBill(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/update_bill", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPharmacyStockAc(term){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/stock_ac&term="+term, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPharmacySuppliers(){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/po_suppliers", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPharmacyOrders(offset, limit){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/orders&offset="+offset+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPharmacyOrder(id){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/order&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPharmacyDNs(id){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/delivery_notes&po_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPharmacyDNSingle(id){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/delivery_note&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPharmacyPurchaseBills(limit, offset, supplier_id){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/purchase_bills&limit="+limit+"&offset="+offset+"&supplier_id="+supplier_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeletePharmacySupplier(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/delete_po_supplier", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddPharmacySupplier(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/add_po_supplier", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditPharmacySupplier(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/edit_po_supplier", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postCreatePharmacyOrder(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/create_order", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postCreatePharmacyDN(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/create_delivery_note", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postCreatePharmacyPurchaseBill(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/create_purchase_bill", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSupplierTransactions(supplier_id, limit, offset, date1, date2){
      return this.httpClient.get(this.REST_API_SERVER + "pharmacy/supplier_transactions&supplier_id="+supplier_id+"&limit="+limit+"&offset="+offset+"&date1="+date1+"&date2="+date2, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getPharmacyStock(search, offset, limit, category, showZero=0){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/stock&search="+search+"&offset="+offset+"&limit="+limit+"&category="+category+"&show_zero="+showZero, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPharmacyStockv2(search, offset, limit){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/stockv2&search="+search+"&offset="+offset+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPharmacyDeletedStock(search, offset, limit){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/deleted&search="+search+"&offset="+offset+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPharmacyCategories(){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/categories&search=", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    

    public getPharmacyReturnLog(search, offset, limit){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/return_log&search="+search+"&offset="+offset+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPharmacySupplierCredit(supplierId){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/supplier_credit&supplier_id="+supplierId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPharmacyPatientReturnLog(search, offset, limit){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/patient_return_log&search="+search+"&offset="+offset+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPharmacyPrescriptions(search, offset, limit){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/prescriptions&search="+search+"&offset="+offset+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddPharmacyStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/add_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditPharmacyStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/edit_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeletePharmacyStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/delete_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postPharmacyOpeningStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/update_opening_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postLogReturnFromStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/log_return_from_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postGodownOpeningStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "godown/update_opening_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postReturnDrug(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/return_drug", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditReturnLog(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/edit_return_log", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postPatientReturnStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/patient_return", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postMedicinePrescription(data){
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/new_prescription", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postProcedurePrescription(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/new_procedure_prescription", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getProcedurePrescription(id){
      return this.httpClient.get(this.REST_API_SERVER +"therapist/view_procedure_prescription&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postLabGenerateBill(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/generate_bill", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postUpdatePayerName(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/update_payer_name", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getMedicinePrescription(id){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/view_prescription&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDuplicateMedicinePrescription(id){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/duplicate_prescription&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientAc(term){
      return this.httpClient.get(this.REST_API_SERVER +"patient/autocomplete&term="+term, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getFormTextSuggestion(text, title, formId="sd"){
      return this.httpClient.get(this.REST_API_SERVER +"client/form_text_suggestion&text="+text+"&title="+title+"&formId="+formId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getFormTextSuggestions(text, title, formId="sd"){
      return this.httpClient.get(this.REST_API_SERVER +"client/form_text_suggestions&text="+text+"&title="+title+"&formId="+formId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getVisitBillData(visit_id){
      return this.httpClient.get(this.REST_API_SERVER +"reception/fetch_visit_billdata&visit_id="+visit_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getAdminProcedures(limit, offset){
      return this.httpClient.get(this.REST_API_SERVER +"admin/procedures_list&limit="+limit+"&offset="+offset, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAdminProcedure(data){
      return this.httpClient.post(this.REST_API_SERVER + "admin/add_procedure", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAdminEditProcedure(data){
      return this.httpClient.post(this.REST_API_SERVER + "admin/edit_procedure", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAdminDeleteProcedure(data){
      return this.httpClient.post(this.REST_API_SERVER + "admin/delete_procedure", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getProcedureAc(term){
      return this.httpClient.get(this.REST_API_SERVER +"therapist/procedures_ac&term="+term, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postProcedureGenerateBill(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/generate_procedure_bill", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getProcedurePrescriptions(search, date, limit, offset, patient_id){
      return this.httpClient.get(this.REST_API_SERVER +"reception/procedure_prescriptions&search="+search+"&date="+date+"&limit="+limit+"&offset="+offset+"&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    

    /* lab */

    public postLabPrescription(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/new_prescription", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLabInvestigations(search, limit, offset){
      return this.httpClient.get(this.REST_API_SERVER +"lab/investigations&search="+search+"&limit="+limit+"&offset="+offset, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLabSamples(){
      return this.httpClient.get(this.REST_API_SERVER +"lab/samples", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLTE(id){
      return this.httpClient.get(this.REST_API_SERVER +"lab/view_entry&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLabGroups(search, limit, offset){
      return this.httpClient.get(this.REST_API_SERVER +"lab/groups&search="+search+"&limit="+limit+"&offset="+offset, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLabTestEntries(search, limit, offset, patient_id){
      return this.httpClient.get(this.REST_API_SERVER +"lab/test_entries&search="+search+"&limit="+limit+"&offset="+offset+"&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLabGroupsList(){
      return this.httpClient.get(this.REST_API_SERVER +"lab/groups_list", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postNewLabTest(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/add_test", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditLabTest(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/edit_test", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteLabTest(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/delete_test", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postLabTestEntry(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/new_test_entry", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postLabTestResult(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/update_test_result", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postMarkLabResultComplete(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/mark_result_complete", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postApproveLabResult(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/approve_report", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    /* lab - stock */
    public getLabStockAc(term){
      return this.httpClient.get(this.REST_API_SERVER +"lab/stock_ac&term="+term, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLabSuppliers(){
      return this.httpClient.get(this.REST_API_SERVER +"lab/po_suppliers", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLabDNSingle(id){
      return this.httpClient.get(this.REST_API_SERVER +"lab/delivery_note&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLabPurchaseBills(limit, offset, supplier_id){
      return this.httpClient.get(this.REST_API_SERVER +"lab/purchase_bills&limit="+limit+"&offset="+offset+"&supplier_id="+supplier_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteLabSupplier(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/delete_po_supplier", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddLabSupplier(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/add_po_supplier", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditLabSupplier(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/edit_po_supplier", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postCreateLabPurchaseBill(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/create_purchase_bill", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLabSupplierTransactions(supplier_id, limit, offset, date1, date2){
      return this.httpClient.get(this.REST_API_SERVER + "lab/supplier_transactions&supplier_id="+supplier_id+"&limit="+limit+"&offset="+offset+"&date1="+date1+"&date2="+date2, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getLabStock(search, offset, limit, category){
      return this.httpClient.get(this.REST_API_SERVER +"lab/stock&search="+search+"&offset="+offset+"&limit="+limit+"&category="+category, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLabDeletedStock(search, offset, limit){
      return this.httpClient.get(this.REST_API_SERVER +"lab/deleted&search="+search+"&offset="+offset+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLabCategories(){
      return this.httpClient.get(this.REST_API_SERVER +"lab/categories&search=", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLabSupplierCredit(supplierId){
      return this.httpClient.get(this.REST_API_SERVER +"lab/supplier_credit&supplier_id="+supplierId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddLabStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/add_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditLabStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/edit_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteLabStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/delete_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postLabReturnDrug(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/return_drug", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postLabEditReturnLog(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/edit_return_log", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLabReturnLog(search, offset, limit){
      return this.httpClient.get(this.REST_API_SERVER +"lab/return_log&search="+search+"&offset="+offset+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postLabConsumablesLog(data){
      return this.httpClient.post(this.REST_API_SERVER + "lab/add_consumable_log", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLabConsumablesLog(limit, offset, date){
      return this.httpClient.get(this.REST_API_SERVER +"lab/consumables_log&offset="+offset+"&limit="+limit+"&date="+date, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }


    /*wallet*/
    public getClientWallet(patient_id, limit, offset){
      return this.httpClient.get(this.REST_API_SERVER + "client/wallet&patient_id="+patient_id+"&limit="+limit+"&offset="+offset, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getClientWalletBalance(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "client/wallet_balance&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postClientWalletAdd(data){
      return this.httpClient.post(this.REST_API_SERVER + "client/wallet_credit", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postClientWalletRefund(data){
      return this.httpClient.post(this.REST_API_SERVER + "client/wallet_debit", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    /* godown */

    public getGodownStockAc(term){
      return this.httpClient.get(this.REST_API_SERVER +"godown/stock_ac&term="+term, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGodownStockInfo(stock){
      return this.httpClient.get(this.REST_API_SERVER +"godown/stock_info&stock="+stock, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGodownSuppliers(){
      return this.httpClient.get(this.REST_API_SERVER +"godown/po_suppliers", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGodownDNSingle(id){
      return this.httpClient.get(this.REST_API_SERVER +"godown/delivery_note&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGodownPurchaseBills(limit, offset, supplier_id){
      return this.httpClient.get(this.REST_API_SERVER +"godown/purchase_bills&limit="+limit+"&offset="+offset+"&supplier_id="+supplier_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteGodownSupplier(data){
      return this.httpClient.post(this.REST_API_SERVER + "godown/delete_po_supplier", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddGodownSupplier(data){
      return this.httpClient.post(this.REST_API_SERVER + "godown/add_po_supplier", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditGodownSupplier(data){
      return this.httpClient.post(this.REST_API_SERVER + "godown/edit_po_supplier", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postCreateGodownPurchaseBill(data){
      return this.httpClient.post(this.REST_API_SERVER + "godown/create_purchase_bill", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGodownRecalculatePurchaseTotal(id){
      return this.httpClient.get(this.REST_API_SERVER + "godown/recalculate_purchase_total&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getGodownSupplierTransactions(supplier_id, limit, offset, date1, date2){
      return this.httpClient.get(this.REST_API_SERVER + "godown/supplier_transactions&supplier_id="+supplier_id+"&limit="+limit+"&offset="+offset+"&date1="+date1+"&date2="+date2, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getGodownStock(search, offset, limit, category, showZero=0){
      return this.httpClient.get(this.REST_API_SERVER +"godown/stock&search="+search+"&offset="+offset+"&limit="+limit+"&category="+category+"&show_zero="+showZero, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGodownDeletedStock(search, offset, limit){
      return this.httpClient.get(this.REST_API_SERVER +"godown/deleted&search="+search+"&offset="+offset+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGodownCategories(){
      return this.httpClient.get(this.REST_API_SERVER +"godown/categories&search=", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGodownReturnLog(search, offset, limit){
      return this.httpClient.get(this.REST_API_SERVER +"godown/return_log&search="+search+"&offset="+offset+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGodownSupplierCredit(supplierId){
      return this.httpClient.get(this.REST_API_SERVER +"godown/supplier_credit&supplier_id="+supplierId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddGodownStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "godown/add_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditGodownStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "godown/edit_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteGodownStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "godown/delete_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postGodownReturnDrug(data){
      return this.httpClient.post(this.REST_API_SERVER + "godown/return_drug", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postGodownEditReturnLog(data){
      return this.httpClient.post(this.REST_API_SERVER + "godown/edit_return_log", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGodownRequests(limit, offset){
      return this.httpClient.get(this.REST_API_SERVER +"godown/requests&limit="+limit+"&offset="+offset, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGodownRequest(id){
      return this.httpClient.get(this.REST_API_SERVER +"godown/request&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    
    public postNewGodownRequest(data){
      return this.httpClient.post(this.REST_API_SERVER + "godown/new_request", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postApproveGodownRequest(data){
      return this.httpClient.post(this.REST_API_SERVER + "godown/approve_request", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDispenseGodownRequest(data){
      return this.httpClient.post(this.REST_API_SERVER + "godown/dispense_request", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    /*Nursing */
    public getNursingStockAc(term){
      return this.httpClient.get(this.REST_API_SERVER +"nursing/stock_ac&term="+term, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getNursingSuppliers(){
      return this.httpClient.get(this.REST_API_SERVER +"nursing/po_suppliers", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getNursingDNSingle(id){
      return this.httpClient.get(this.REST_API_SERVER +"nursing/delivery_note&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getNursingPurchaseBills(limit, offset, supplier_id){
      return this.httpClient.get(this.REST_API_SERVER +"nursing/purchase_bills&limit="+limit+"&offset="+offset+"&supplier_id="+supplier_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteNursingSupplier(data){
      return this.httpClient.post(this.REST_API_SERVER + "nursing/delete_po_supplier", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddNursingSupplier(data){
      return this.httpClient.post(this.REST_API_SERVER + "nursing/add_po_supplier", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditNursingSupplier(data){
      return this.httpClient.post(this.REST_API_SERVER + "nursing/edit_po_supplier", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postCreateNursingPurchaseBill(data){
      return this.httpClient.post(this.REST_API_SERVER + "nursing/create_purchase_bill", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getNursingSupplierTransactions(supplier_id, limit, offset, date1, date2){
      return this.httpClient.get(this.REST_API_SERVER + "nursing/supplier_transactions&supplier_id="+supplier_id+"&limit="+limit+"&offset="+offset+"&date1="+date1+"&date2="+date2, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getNursingStock(search, offset, limit, category){
      return this.httpClient.get(this.REST_API_SERVER +"nursing/stock&search="+search+"&offset="+offset+"&limit="+limit+"&category="+category, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getNursingDeletedStock(search, offset, limit){
      return this.httpClient.get(this.REST_API_SERVER +"nursing/deleted&search="+search+"&offset="+offset+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getNursingCategories(){
      return this.httpClient.get(this.REST_API_SERVER +"nursing/categories&search=", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getNursingSupplierCredit(supplierId){
      return this.httpClient.get(this.REST_API_SERVER +"nursing/supplier_credit&supplier_id="+supplierId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddNursingStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "nursing/add_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditNursingStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "nursing/edit_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteNursingStock(data){
      return this.httpClient.post(this.REST_API_SERVER + "nursing/delete_stock", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postNursingReturnDrug(data){
      return this.httpClient.post(this.REST_API_SERVER + "nursing/return_drug", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postNursingEditReturnLog(data){
      return this.httpClient.post(this.REST_API_SERVER + "nursing/edit_return_log", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getNursingReturnLog(search, offset, limit){
      return this.httpClient.get(this.REST_API_SERVER +"nursing/return_log&search="+search+"&offset="+offset+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postNursingBill(data){
      return this.httpClient.post(this.REST_API_SERVER + "nursing/new_bill", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postNursingConsumablesLog(data){
      return this.httpClient.post(this.REST_API_SERVER + "nursing/add_consumable_log", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getNursingConsumablesLog(limit, offset, date){
      return this.httpClient.get(this.REST_API_SERVER +"nursing/consumables_log&offset="+offset+"&limit="+limit+"&date="+date, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }


    /* settings */

    public getRegRenewal(){
      return this.httpClient.get(this.REST_API_SERVER +"admin/reg_renewal", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSettings(){
      return this.httpClient.get(this.REST_API_SERVER +"admin/get_settings", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSaveRegRenewal(data){
      return this.httpClient.post(this.REST_API_SERVER + "admin/update_reg_renewal", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postNewPurchaseRequest(data){
      return this.httpClient.post(this.REST_API_SERVER + "stock/new_purchase_request", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPurchaseRequests(limit, offset){
      return this.httpClient.get(this.REST_API_SERVER +"stock/purchase_requests&limit="+limit+"&offset="+offset, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPurchaseRequest(id){
      return this.httpClient.get(this.REST_API_SERVER +"stock/purchase_request&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postApprovePurchaseRequest(data){
      return this.httpClient.post(this.REST_API_SERVER + "stock/approve_purchase_request", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postMarkVisitComplementary(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/mark_visit_complementary", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }


    /*Nabidh */
    public sendNabidhA04(appointment_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/nabidh_a04&appointment_id="+appointment_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public sendNabidhFile(data){
      return this.httpClient.post(this.REST_API_SERVER + "hl7/file_upload", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientDiagnosis(id){
      return this.httpClient.get(this.REST_API_SERVER +"client/list_patient_diagnosis&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDiagTypes(){
      return this.httpClient.get(this.REST_API_SERVER +"client/diagnosis_types", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postPatientDiagnosis(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/add_patient_diagnosis", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeletePatientDiagnosis(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/delete_patient_diagnosis", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getVitalHeads(){
      return this.httpClient.get(this.REST_API_SERVER +"client/load_vital_heads", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientVitals(id){
      return this.httpClient.get(this.REST_API_SERVER +"client/list_patient_vitals&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postPatientVitals(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/add_patient_vitals", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeletePatientVitals(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/delete_patient_vitals", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getProblemStatus(){
      return this.httpClient.get(this.REST_API_SERVER +"client/load_problem_status", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientProblems(id){
      return this.httpClient.get(this.REST_API_SERVER +"client/list_patient_problems&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postPatientProblem(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/add_patient_problem", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeletePatientProblem(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/delete_patient_problem", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getAllergyCodes(){
      return this.httpClient.get(this.REST_API_SERVER +"client/allergy_codes", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postPatientAllergy(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/add_patient_allergy", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    

    public getPatientAllergies(patient_id){
      return this.httpClient.get(this.REST_API_SERVER +"client/list_patient_allergies&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeletePatientAllergy(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/delete_patient_allergy", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postPatientMed(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/add_patient_med", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getPatientMed(patient_id){
      return this.httpClient.get(this.REST_API_SERVER +"client/list_patient_med&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeletePatientMedication(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/delete_patient_medication", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postPatientProcedure(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/add_patient_procedure", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getPatientProcedures(patient_id){
      return this.httpClient.get(this.REST_API_SERVER +"client/list_patient_procedures&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    
    public postDeletePatientProcedure(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/delete_patient_procedure", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postPatientSh(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/add_patient_sh", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getPatientSh(patient_id){
      return this.httpClient.get(this.REST_API_SERVER +"client/list_patient_sh&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeletePatientSh(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/delete_patient_sh", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getFhRelationships(){
      return this.httpClient.get(this.REST_API_SERVER +"client/fh_relationships", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postPatientFh(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/add_patient_fh", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getPatientFh(patient_id){
      return this.httpClient.get(this.REST_API_SERVER +"client/list_patient_fh&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeletePatientFh(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "client/delete_patient_fh", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getNabidhStatus(patient_id){
      return this.httpClient.get(this.REST_API_SERVER +"client/nabidh_optout_status&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postNabidhOptout(data) {
      return this.httpClient.post(this.REST_API_SERVER + "client/post_nabidh_optout_status", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postNabidhVipFlag(data) {
      return this.httpClient.post(this.REST_API_SERVER + "client/post_nabidh_vip_flag", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postNabidhMerge(data) {
      return this.httpClient.post(this.REST_API_SERVER + "client/nabidh_merge", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postPatientInsurance(data) {
      return this.httpClient.post(this.REST_API_SERVER + "client/save_patient_insurance", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getPatientInsurance(patient_id){
      return this.httpClient.get(this.REST_API_SERVER +"client/insurance_info&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postPatientDischarge(data) {
      return this.httpClient.post(this.REST_API_SERVER + "client/discharge_patient", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postPatientCancelAdmission(data) {
      return this.httpClient.post(this.REST_API_SERVER + "client/cancel_patient_admission", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEditDNRow(data) {
      return this.httpClient.post(this.REST_API_SERVER + "godown/edit_dn_row", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEditDNTransaction(data) {
      return this.httpClient.post(this.REST_API_SERVER + "godown/edit_dn_meta", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEditDNField(data) {
      return this.httpClient.post(this.REST_API_SERVER + "godown/edit_dn_field", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteDN(data) {
      return this.httpClient.post(this.REST_API_SERVER + "godown/delete_dn", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEditProcedureRow(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/edit_procedure_body", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteProcedureRow(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/delete_procedure_body", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeletePrescriptionRow(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/delete_prescription_body", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeletePrescriptionTaperingRow(data) {
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/delete_prescription_tapering", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUpdatePrescriptionReview(data) {
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/update_prescription_review", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEditLabRow(data) {
      return this.httpClient.post(this.REST_API_SERVER + "lab/edit_entry_body", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteLabRow(data) {
      return this.httpClient.post(this.REST_API_SERVER + "lab/delete_entry_body", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postLTESetAbnormal(data) {
      return this.httpClient.post(this.REST_API_SERVER + "lab/lte_set_abnormal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEditPrescriptionRow(data) {
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/edit_prescription_body", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEditPrescriptionTaperingRow(data) {
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/edit_prescription_tapering", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postRecalculatePharmaBillTotal(data) {
      return this.httpClient.post(this.REST_API_SERVER + "pharmacy/recalculate_bill_total", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUpdateBCAmount(data) {
      return this.httpClient.post(this.REST_API_SERVER + "billing/update_bc_amount", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getOPCardPresets(){
      return this.httpClient.get(this.REST_API_SERVER +"client/opcard_presets", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postUpdateOPCPreset(data) {
      return this.httpClient.post(this.REST_API_SERVER + "client/save_opc_preset", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteOPCPreset(data) {
      return this.httpClient.post(this.REST_API_SERVER + "client/delete_opc_preset", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getClientLabResults(patientId){
      return this.httpClient.get(this.REST_API_SERVER +"lab/patient_results&patient_id="+patientId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getClientProcedures(patientId){
      return this.httpClient.get(this.REST_API_SERVER +"therapist/patient_procedures&patient_id="+patientId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getClientOpCards(patientId){
      return this.httpClient.get(this.REST_API_SERVER +"therapist/patient_opcards&patient_id="+patientId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientAlerts(patientId){
      return this.httpClient.get(this.REST_API_SERVER +"therapist/patient_alerts&patient_id="+patientId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postPatientAlert(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/new_patient_alert", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEditPatientAlert(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/edit_patient_alert", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeletePatientAlert(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/delete_patient_alert", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getClientMedicines(patientId){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/patient_prescriptions&patient_id="+patientId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPrescriptionPDF(id){
      return this.httpClient.get(this.REST_API_SERVER +"pharmacy/prescription_pdf&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postReferProcedure(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/refer_procedure", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUpdatePPRDiscount(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/update_procedure_discount", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }    

    public getGodownItemDetails(item){
      return this.httpClient.get(this.REST_API_SERVER +"godown/item_details&item="+item, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditBillExtName(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "billing/update_ext_name", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }  
    
    public getDocPrescriptionOptions(){
      return this.httpClient.get(this.REST_API_SERVER +"therapist/prescription_options", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postMarkDocAvailability(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "account/mark_doc_availability", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }
    
    public postUpdateVisitRemarks(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/update_visit_remarks", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postPatientMerge(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "patient/merge", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postSubmitQuantityEditRequest(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "godown/new_qty_edit_request", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getStockEditRequests(date, stockName, limit, offset){
      return this.httpClient.get(this.REST_API_SERVER +"godown/stock_edit_requests&date="+date+"&stockName="+stockName+"&limit="+limit+"&offset="+offset, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getApproveStockEditRequest(id){
      return this.httpClient.get(this.REST_API_SERVER +"godown/approve_stock_qty_edit&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDocProcedureBills(date, limit, offset){
      return this.httpClient.get(this.REST_API_SERVER +"therapist/procedure_bills&date="+date+"&limit="+limit+"&offset="+offset, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }


}
