import { Component, OnInit, ChangeDetectorRef, NgZone  } from '@angular/core';
import { DataService } from '../../data.service';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  constructor(private dataService: DataService, private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone,) { }
  
  doctors:any = [];
  suppliers:any = [];
  users:any = [];
  reportModes:any = {
    docwisePR: 1,
    prwiseDoc:1
  }
  
  selectedMedicines:any = [
    
  ];
  selectedMedicines3:any = [
    
  ];
  medListInput:any = [];

  selectedMedicines2:any = [
    
  ];
  medListInput2:any = [];
  medListInput3:any = [];
  godownSpecMode:any = 1;
  hsnBillType:any = 2;
  gstBillType:any = 2;
  selectedGodownProduct:any = "";
  shifts:any = [];
  selectedShift:any = {};
  

  ngOnInit() {
    this.loadDoctors();
    this.loadSuppliers();
    this.loadUsers();
    //this.loadShifts();

    let context = this;

    $('body').on('focus', '.stock-input, .stock-input3', function(){
      let moi = $(this);
      let i = moi.parent().parent().attr("data-index");
      let arrayKey = moi.attr("data-arraykey");
      //@ts-ignore
      moi.autocomplete({
        source: function(request, response) {
            $.ajax({
              url:context.dataService.REST_API_SERVER + 'pharmacy/autocomplete_all',
              type:'GET',
              dataType:'json',
              data: {term: request.term},
              headers: {key:localStorage.getItem('key')},
              success: function(responseData) {
                
                var array = responseData.map(function(element){
                  return {
                    value: element['drug'] + " (Balance: "+element['balance']+")", 
                    id: element['id'],
                    drug: element['drug'],
                    batch: element['batch'],
                    expiry: element['expiry'],
                    balance: element['balance'],
                    rate: element['rate'],
                  };
                });
                response(array);
    
              }
            })
          }, 
          select: function(event, ui) {
              moi.val(ui.item.drug);
              context.ngZone.run(() => {
                //context.selectedMedicines.push(ui.item.drug);
                context[arrayKey].push(ui.item.drug);
                context.medListInput3 = "";
                context.medListInput = "";
              });
              
              return false;
          }
      });
    });

    $('body').on('focus', '.stock-input2', function(){
      let moi = $(this);
      let i = moi.parent().parent().attr("data-index");
      //@ts-ignore
      moi.autocomplete({
        source: function(request, response) {
            $.ajax({
              url:context.dataService.REST_API_SERVER + 'pharmacy/autocomplete',
              type:'GET',
              dataType:'json',
              data: {term: request.term},
              headers: {key:localStorage.getItem('key')},
              success: function(responseData) {
                
                var array = responseData.map(function(element){
                  return {
                    value: element['drug'] + " (Balance: "+element['balance']+")", 
                    id: element['id'],
                    drug: element['drug'],
                    batch: element['batch'],
                    expiry: element['expiry'],
                    balance: element['balance'],
                    rate: element['rate'],
                  };
                });
                response(array);
    
              }
            })
          }, 
          select: function(event, ui) {
              moi.val(ui.item.drug);
              context.ngZone.run(() => {
                context.selectedMedicines2.push(ui.item.drug);
                context.medListInput2 = "";
              });
              
              return false;
          }
      });
    });

    $('body').on('focus', '.stock-input-godown', function(){
      let moi = $(this);
      let i = moi.parent().parent().attr("data-index");
      //@ts-ignore
      moi.autocomplete({
        source: function(request, response) {
            $.ajax({
              url:context.dataService.REST_API_SERVER + 'godown/autocomplete',
              type:'GET',
              dataType:'json',
              data: {term: request.term},
              headers: {key:localStorage.getItem('key')},
              success: function(responseData) {
                
                var array = responseData.map(function(element){
                  return {
                    value: element['drug'], 
                    id: element['id'],
                    drug: element['drug'],
                    batch: element['batch'],
                    expiry: element['expiry'],
                    balance: element['balance'],
                    rate: element['rate'],
                  };
                });
                response(array);
    
              }
            })
          }, 
          select: function(event, ui) {
              moi.val(ui.item.drug);
              context.selectedGodownProduct = ui.item.drug;
              
              return false;
          }
      });
    });
  }

  generateBillsReport(data){

    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/bills&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });   
    } else {
      alert("Please choose an interval not more than 31 days");
    }

    
  }

  generateCancelledBillsReport(data){

    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/cancelled_bills&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });  
    } else {
      alert("Please choose an interval not more than 31 days");
    }

    
  }

  generatePatientsReport(data){

    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/daily_patients&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });
    } else {
      alert("Please choose an interval not more than 31 days");
    }

    
  }

  generateDocPatientsReport(data){
    
    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/docwise_patients&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });
    } else {
      alert("Please choose an interval not more than 31 days");
    }

    
  }

  HSNSalesReport(data){
    
    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/sales_by_hsn&date1="+data.date1+"&date2="+data.date2+"&bill_type="+this.hsnBillType+"&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });
    } else {
      alert("Please choose an interval not more than 31 days");
    }

    
  }

  GSTSalesReport(data){
    
    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/sales_by_gst&date1="+data.date1+"&date2="+data.date2+"&bill_type="+this.gstBillType+"&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });
    } else {
      alert("Please choose an interval not more than 31 days");
    }

    
  }

  currentStock(){
    this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
      if(res.status == 0) {
        
        window.open(this.dataService.REST_API_SERVER + "reports/pharmacy_godown_stock&token="+res.token)

      } else {
        alert(res.msg);
      }
    });
  }

  currentStockSeparate(data){
    this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
      if(res.status == 0) {
        
        window.open(this.dataService.REST_API_SERVER + "reports/separate_stock&mode="+data.mode+"&token="+res.token)

      } else {
        alert(res.msg);
      }
    });
  }

  expiryDue(data){
    this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
      if(res.status == 0) {
        
        window.open(this.dataService.REST_API_SERVER + "reports/expiry_due&mode="+data.mode+"&token="+res.token)

      } else {
        alert(res.msg);
      }
    });
  }

  godownTransferLog(data){

    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/godown_transfer_log&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });  
    } else {
      alert("Please choose an interval not more than 31 days");
    }
    
  }

  pharmacyPatientReturns(data){

    this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
      if(res.status == 0) {
        
        window.open(this.dataService.REST_API_SERVER + "reports/patient_stock_returns&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)

      } else {
        alert(res.msg);
      }
    });  
    
  }

  sellerStockReturns(data){

    this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
      if(res.status == 0) {
        
        window.open(this.dataService.REST_API_SERVER + "reports/seller_stock_returns&stock="+data.stock+"&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)

      } else {
        alert(res.msg);
      }
    });  
    
  }

  complementaryPatients(data){
    if(this.compareDates(data.date1, data.date2, 90)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/complementary_visits&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)

        } else {
          alert(res.msg);
        }
      });  
    } else {
      alert("Please choose an interval not more than 90 days");
    }
    
  }

  internationalPatients(data){
    if(this.compareDates(data.date1, data.date2, 90)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/international_visits&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)

        } else {
          alert(res.msg);
        }
      });  
    } else {
      alert("Please choose an interval not more than 90 days");
    }
    
  }

  godownPurchase(data){
    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/purchase_report&date1="+data.date1+"&date2="+data.date2+"&supplier="+data.supplier+"&stock="+this.selectedGodownProduct+"&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });  
    } else {
      alert("Please choose an interval not more than 31 days");
    }
    
  }

  receptionCollection(data){
    this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
      if(res.status == 0) {
        let startTime = this.shifts[this.selectedShift].start_time;
        let endTime = this.shifts[this.selectedShift].end_time;
        window.open(this.dataService.REST_API_SERVER + "reports/reception_collection&date="+data.date+"&start_time="+startTime+"&end_time="+endTime+"&token="+res.token)

      } else {
        alert(res.msg);
      }
    });
  }

  receptionCollectionFull(data){
    this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
      if(res.status == 0) {
        
        window.open(this.dataService.REST_API_SERVER + "reports/reception_collection_full&date="+data.date+"&token="+res.token)

      } else {
        alert(res.msg);
      }
    });
  }

  labCollection(data){
    this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
      if(res.status == 0) {
        let startTime = this.shifts[this.selectedShift].start_time;
        let endTime = this.shifts[this.selectedShift].end_time;
        window.open(this.dataService.REST_API_SERVER + "reports/lab_user_collection&date="+data.date+"&start_time="+startTime+"&end_time="+endTime+"&token="+res.token)

      } else {
        alert(res.msg);
      }
    });
  }

  pharmacyCollection(data){

    
    this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
      if(res.status == 0) {
        let startTime = this.shifts[this.selectedShift].start_time;
        let endTime = this.shifts[this.selectedShift].end_time;

        window.open(this.dataService.REST_API_SERVER + "reports/pharmacy_user_collection&date="+data.date+"&start_time="+startTime+"&end_time="+endTime+"&token="+res.token)

      } else {
        alert(res.msg);
      }
    });
  }

  totalCollectionSummary(data){
    this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
      if(res.status == 0) {
        
        window.open(this.dataService.REST_API_SERVER + "reports/total_collection_summary&date="+data.date+"&token="+res.token)

      } else {
        alert(res.msg);
      }
    });
  }

  individualCollectionSummary(data){
    this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
      if(res.status == 0) {
        
        window.open(this.dataService.REST_API_SERVER + "reports/total_collection_summary&date="+data.date+"&userId="+data.user+"&token="+res.token)

      } else {
        alert(res.msg);
      }
    });
  }

  testwiseLab(data){
    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/testwise_lab&date1="+data.date1+"&date2="+data.date2+"&testName="+data.testName+"&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });  
    } else {
      alert("Please choose an interval not more than 31 days");
    }
    
  }


  loadDoctors(){
    this.dataService.getTherapists(0).subscribe( (data: any ) =>{
      if(data.status == 0){
          this.doctors = data.therapists;
      }
      
    });
  }

  loadUsers(){
    this.dataService.getBillingUsers().subscribe( (data: any ) =>{
      if(data.status == 0){
          this.users = data.list;
      }
      
    });
  }

  loadShifts(date=""){
    
    // Check if the date is either an empty string or a valid date starting with "20"
    if (date && !/^(20\d{2})-\d{2}-\d{2}$/.test(date)) {
      console.error("Invalid date format. Date must be in 'YYYY-MM-DD' format with a year starting with '20'.");
      return;
    }

    this.dataService.getUserShifts(date).subscribe( (data: any ) =>{
      if(data.status == true){
          this.shifts = data.shifts;
          if (this.shifts.length > 0) {
            this.selectedShift = 0;
          } else {
            this.selectedShift = null; // Handle case when no shifts are available
          }
      }
      
    });
  }

  loadSuppliers(){
    this.dataService.getGodownSuppliers().subscribe( (data: any )=> {
      this.suppliers = data;  
    });
  }

  setReportMode(item, val){
    this.reportModes[item] = val;
  }

  docwiseProcedure(data){
    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          if(this.reportModes.docwisePR == 1){
            window.open(this.dataService.REST_API_SERVER + "reports/docwise_procedure&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)
          } else {
            window.open(this.dataService.REST_API_SERVER + "reports/docwise_procedure_detailed&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)
          }
          
  
        } else {
          alert(res.msg);
        }
      });  
    } else {
      alert("Please choose an interval not more than 31 days");
    }
    
  }

  prwiseDoc(data){
    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          if(this.reportModes.prwiseDoc == 1){
            window.open(this.dataService.REST_API_SERVER + "reports/procedurewise_doc_consolidated&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)
          } else {
            //window.open(this.dataService.REST_API_SERVER + "reports/procedurewise_doc_detailed&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)
            //alert("Report not ready");
          }
          
  
        } else {
          alert(res.msg);
        }
      });  
    } else {
      alert("Please choose an interval not more than 31 days");
    }
    
  }


  compareDates(date1, date2, max=31) {
    date1 = new Date(date1);
    
    if(date2 == null || date2 == ""){
      date2 = date1;
    } else {
      date2 = new Date(date2);
    }

    
    
    const diffInMs = Math.abs(date2 - date1);
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays <= max;
  }


  /* monthly reports */

  specificMedicinesReport(data){

    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/specific_medicines&date1="+data.date1+"&date2="+data.date2+"&list="+this.selectedMedicines+ "&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });   
    } else {
      alert("Please choose an interval not more than 31 days");
    }

    
  }
  specificMedicinesReportAllSales(data){

    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/medicine_sales&date1="+data.date1+"&date2="+data.date2+"&list="+this.selectedMedicines3+ "&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });   
    } else {
      alert("Please choose an interval not more than 31 days");
    }

    
  }

  specificMedicinesGodownReport(data){

    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/specific_medicines_sales_pharmacy&date1="+data.date1+"&date2="+data.date2+"&list="+this.selectedMedicines2+ "&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });   
    } else {
      alert("Please choose an interval not more than 31 days");
    }

    
  }

  monthlyIncomeReport(data){

    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/income_report&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });   
    } else {
      alert("Please choose an interval not more than 31 days");
    }

    
  }

  taxablePharmaReport(data){

    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/taxable_bills_pharmacy&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });   
    } else {
      alert("Please choose an interval not more than 31 days");
    }

    
  }

  taxableProcedureReport(data){

    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          
          window.open(this.dataService.REST_API_SERVER + "reports/taxable_bills_procedure&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });   
    } else {
      alert("Please choose an interval not more than 31 days");
    }

    
  }

  nonTaxableProcedureReport(data){

    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          window.open(this.dataService.REST_API_SERVER + "reports/nontaxable_bills_procedure&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });   
    } else {
      alert("Please choose an interval not more than 31 days");
    }

    
  }

  monthlySummaryReport(data){

    if(this.compareDates(data.date1, data.date2)){
      this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
        if(res.status == 0) {
          window.open(this.dataService.REST_API_SERVER + "reports/monthly_summary&date1="+data.date1+"&date2="+data.date2+"&token="+res.token)
  
        } else {
          alert(res.msg);
        }
      });   
    } else {
      alert("Please choose an interval not more than 31 days");
    }

    
  }

  labTestsStatement(data){
    this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
      if(res.status == 0) {
        
        window.open(this.dataService.REST_API_SERVER + "reports/lab_tests&token="+res.token)

      } else {
        alert(res.msg);
      }
    });  
    
  }


  setGodownSpecMode(mode){
    this.godownSpecMode = mode;
    this.selectedMedicines2 = [];
  }

  setHsnBillType(type){
    this.hsnBillType = type;
  }

  setGstBillType(type){
    this.gstBillType = type;
  }



}
