import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './dashboard/home/home.component';
//import { PatientComponent } from './dashboard/patient/patient.component';
//import { OverviewComponent } from './dashboard/patient/overview/overview.component';
//import { ScheduleComponent } from './dashboard/schedule/schedule.component';
import { PatientsListComponent } from './dashboard/patients-list/patients-list.component';
import { LoginComponent } from './login/login.component';
import { ReceptionistHomeComponent } from './dashboard/receptionist-home/receptionist-home.component';
//import { PersonalDetailsComponent } from './dashboard/patient/personal-details/personal-details.component';
import { EnquiryComponent } from './dashboard/enquiry/enquiry.component';
import { BillingComponent } from './dashboard/billing/billing.component';
import { NotificationComponent } from './dashboard/notification/notification.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
//import { EventsComponent } from './dashboard/events/events.component';
//import { SymptomsDiagnosisComponent } from './dashboard/patient/symptoms-diagnosis/symptoms-diagnosis.component';
//import { IndividualScheduleComponent } from './dashboard/schedule-individual/schedule-individual.component';
import { ViewerComponent } from './viewer/viewer.component';
//import { StatisticsComponent } from './dashboard/statistics/statistics.component';
//import { StatisticsOverviewComponent } from './dashboard/statistics/overview/overview.component';
//import { StatisticsDemographyComponent } from './dashboard/statistics/demography/demography.component';
//import { StatisticsRevenueComponent } from './dashboard/statistics/revenue/revenue.component';
//import { StatisticsDisabilityComponent } from './dashboard/statistics/disability/disability.component';
//import { UsersComponent } from './dashboard/users/users.component';
//import { ServiceComponent } from './dashboard/service/service.component';
//import { InventoryComponent } from './dashboard/inventory/inventory.component';
//import { BranchesComponent } from './dashboard/branches/branches.component';
//import { PurchaseOrdersComponent } from './dashboard/purchase-orders/purchase-orders.component';
//import { SalesComponent } from './dashboard/sales/sales.component';
//import { TransferLogComponent } from './dashboard/transfer-log/transfer-log.component';
//import { AssetsComponent } from './dashboard/assets/assets.component';
//import { HometherapyGoalsComponent } from './dashboard/hometherapy-goals/hometherapy-goals.component';
//import { HometherapyComponent } from './dashboard/patient/hometherapy/hometherapy.component';
//import { PurchaseOrderComponent } from './dashboard/purchase-order/purchase-order.component';
//import { PurchaseHistoryComponent } from './dashboard/purchase-history/purchase-history.component';
//import { PatientFeedbackComponent } from './patient-feedback/patient-feedback.component';
//import { FeedbacksComponent } from './dashboard/feedbacks/feedbacks.component';
//import { FormBuilderComponent } from './dashboard/form-builder/form-builder.component';
//import { FormSubmissionsComponent } from './dashboard/form-submissions/form-submissions.component';
//import { FormSubmissionComponent } from './dashboard/form-submission/form-submission.component';

//import { ChildHistoryComponent } from './dashboard/patient/child-history/child-history.component';
//import { IndexComponent } from './dashboard/patient/child-history/index/index.component';
//import { MedicalFamilyHistoryComponent } from './dashboard/patient/child-history/medical-family-history/medical-family-history.component';
//import { LanguageComponent } from './dashboard/patient/child-history/language/language.component';
//import { SocioFamilyComponent } from './dashboard/patient/child-history/socio-family/socio-family.component';
//import { ChildRegistrationComponent } from './dashboard/patient/child-history/child-registration/child-registration.component';
//import { ChildInformationComponent } from './dashboard/patient/child-history/child-information/child-information.component';
//import { FamilyHistoryComponent } from './dashboard/patient/child-history/family-history/family-history.component';
//import { BirthHistoryComponent } from './dashboard/patient/child-history/birth-history/birth-history.component';
//import { MedicalHistoryComponent } from './dashboard/patient/child-history/medical-history/medical-history.component';
//import { BehaviouralHistoryComponent } from './dashboard/patient/child-history/behavioural-history/behavioural-history.component';
//import { EduHistoryComponent } from './dashboard/patient/child-history/edu-history/edu-history.component';
//import { ChildDevInventoryComponent } from './dashboard/patient/child-dev-inventory/child-dev-inventory.component';
import { ResourceComponent } from './dashboard/resource/resource.component';
//import { HomeprogramComponent } from './dashboard/patient/homeprogram/homeprogram.component';
import { ChatWindowComponent } from './videochat/chat-window/chat-window.component';
//import { ResourceLibraryComponent } from './dashboard/resource-library/resource-library.component';
//import { HomeprogramInternalComponent } from './dashboard/patient/homeprogram-internal/homeprogram-internal.component';
//import { SignupComponent } from './signup/signup.component';
//import { SuperAdminComponent } from './dashboard/super-admin/super-admin.component';
import { BillComponent } from './dashboard/bill/bill.component';
//import { RecentActivitiesComponent } from './dashboard/recent-activities/recent-activities.component';
import { PatientsBranchComponent } from './dashboard/patients-list/patients-branch/patients-branch.component';
import { PatientsReferredComponent } from './dashboard/patients-list/patients-referred/patients-referred.component';
//import { TbcfComponent } from './dashboard/patient/tbcf/tbcf.component';
//import { InfancyComponent } from './dashboard/patient/child-history/infancy/infancy.component';
//import { AdditionalInfoComponent } from './dashboard/patient/child-history/additional-info/additional-info.component';
//import { AnnexeComponent } from './dashboard/patient/child-history/annexe/annexe.component';
//import { CdiResultComponent } from './dashboard/patient/cdi-result/cdi-result.component';
//import { TagsComponent } from './dashboard/tags/tags.component';
//import { FormComponent } from './dashboard/patient/form/form.component';
//import { AppointmentsComponent } from './dashboard/patient/appointments/appointments.component';
import { AnalyticsComponent } from './dashboard/analytics/analytics.component';
//import { SettingsComponent } from './dashboard/settings/settings.component';
//import { IdeComponent } from './dashboard/patient/ide/ide.component';
//import { GoalsActivitiesComponent } from './dashboard/patient/goals-activities/goals-activities.component';
//import { SurveyComponent } from './dashboard/patient/survey/survey.component';
//import { ChecklistComponent } from './dashboard/patient/checklist/checklist.component';
//import { MrRecordComponent } from './dashboard/patient/mr-record/mr-record.component';
import { ScheduleFlexComponent } from './dashboard/schedule-flex/schedule-flex.component';
import { GenerateBillComponent } from './dashboard/generate-bill/generate-bill.component';
//import { LessonPlanComponent } from './dashboard/patient/lesson-plan/lesson-plan.component';
//import { SessionReportComponent } from './dashboard/patient/session-report/session-report.component';
//import { BillHeadsComponent } from './dashboard/bill-heads/bill-heads.component';
//import { AssessmentsComponent } from './dashboard/patient/assessments/assessments.component';
import { PatientProfileComponent } from './dashboard/patient-profile/patient-profile.component';
import { PatientOverviewComponent } from './dashboard/patient-overview/patient-overview.component';
import { PatientGoalsComponent } from './dashboard/patient-goals/patient-goals.component';
import { ReviewFormComponent } from './dashboard/review-form/review-form.component';
import { AddRemarksComponent } from './dashboard/add-remarks/add-remarks.component';
import { AsesmnttActivityComponent } from './dashboard/asesmntt-activity/asesmntt-activity.component';
import { GenerateReportComponent } from './dashboard/generate-report/generate-report.component';
import { ProfileAssesementComponent } from './dashboard/profile-assesement/profile-assesement.component';
import { ProgressTrackerComponent } from './dashboard/progress-tracker/progress-tracker.component';
import { AssesmentToolComponent } from './dashboard/assesment-tool/assesment-tool.component';
import { PatientsNewComponent } from './dashboard/patients-new/patients-new.component';
import { VchatComponent } from './videochat/vchat/vchat.component';
import { SiteAnalyticsComponent } from './dashboard/analytics/site-analytics/site-analytics.component';
import { UserProfileComponent } from './dashboard/profile/user-profile/user-profile.component';
import { MaFormComponent } from './dashboard/patient-profile/ma-form/ma-form.component';
import { TicketsComponent } from './dashboard/tickets/tickets.component';
import { ClientBasicProfileComponent } from './dashboard/client-basic-profile/client-basic-profile.component';
import { AudioTestComponent } from './dashboard/audio-test/audio-test.component';
import { ProfileInfoComponent } from './dashboard/profile-info/profile-info.component';
import { PatientBillsProfileComponent } from './dashboard/patient-bills-profile/patient-bills-profile.component';
import { ClientWalletComponent } from './dashboard/client-wallet/client-wallet.component';
import { PatientLabresultsComponent } from './dashboard/patient-labresults/patient-labresults.component';
import { PatientProcedurelistComponent } from './dashboard/patient-procedurelist/patient-procedurelist.component';
import { ReportsComponent } from './dashboard/reports/reports.component';
import { PatientPrescriptionsComponent } from './dashboard/patient-prescriptions/patient-prescriptions.component';
import { PatientOpcardsComponent } from './dashboard/patient-opcards/patient-opcards.component';

const routes: Routes = [{
  path:'dashboard', component:DashboardComponent, 
  children:[
        {
          path:'#', component:HomeComponent
        },
        {
          path:'tickets', component:TicketsComponent
        },
        {
          path:'profile-re', loadChildren: () => 
              import('./profile/profile.module').then(m => m.ProfileModule)
        },
        {
          path:'clinical-tools/:id', loadChildren: () => 
              import('./clinical-tools/clinical-tools.module').then(m => m.ClinicalToolsModule)
        },
        {
          path:'compass', loadChildren: () => 
              import('./compass/compass.module').then(m => m.CompassModule)
        },
        {
          path:'admin', loadChildren: () => 
              import('./admin/admin.module').then(m => m.AdminModule)
        },
        {
          path:'pages', loadChildren: () => 
              import('./pages/pages.module').then(m => m.PagesModule)
        },
        {
          path:'sa', loadChildren: () => 
              import('./sa/sa.module').then(m => m.SaModule)
        },
        {
          path:'consultant', loadChildren: () => 
              import('./consultant/consultant.module').then(m => m.ConsultantModule)
        },
        {
          path:'tbc', loadChildren: () => 
              import('./tbc-report/tbc-report.module').then(m => m.TbcReportModule)
          
        },
        {
          path:'super-analytics', loadChildren: () => 
              import('./super-analytics/super-analytics.module').then(m => m.SuperAnalyticsModule)
          
        },
        {
          path:'forms', loadChildren: () => 
              import('./forms/forms.module').then(m => m.FormsModule)
          
        },
        {
          path:'report', loadChildren: () => 
              import('./report/report.module').then(m => m.ReportModule)
          
        },
        {
          path:'preset-form', loadChildren: () => 
              import('./preset-forms/preset-forms.module').then(m => m.PresetFormsModule)
          
        },
        {
          path:'pharmacy', loadChildren: () => 
              import('./pharmacy/pharmacy.module').then(m => m.PharmacyModule)
        },
        {
          path:'godown', loadChildren: () => 
              import('./godown/godown.module').then(m => m.GodownModule)
        },
        {
          path:'lab', loadChildren: () => 
              import('./lab/lab.module').then(m => m.LabModule)
          
        },
        {
          path:'nursing', loadChildren: () => 
              import('./nursing/nursing.module').then(m => m.NursingModule)
          
        },
        {
          path:'doctors', loadChildren: () => 
              import('./doctors/doctors.module').then(m => m.DoctorsModule)
          
        },
        {
          path:'home', component:HomeComponent,
        },
        
        {
          path:'analytics-0', 
          component:AnalyticsComponent
          //loadChildren:() => import('./dashboard/analytics/analytics.component').then(m=> m.AnalyticsComponent)
        },
        {
          path: 'patient/:id/child-history/edit/child-registration', redirectTo:'tbc/:id/child-history/child-registration'
        },
        {
          path: 'patient/:id/session-report', redirectTo:'tbc/:id/session-report'
        },
        {
          path:'bill/:id', component:BillComponent,
        },
        {
          path:'bill/:id', component:BillComponent,
        },
        {
          path:'reports', component:ReportsComponent,
        },
        
        
    {
      path:'patientsz', component:PatientsNewComponent
    },

    {
      path:'video_chat', component:VchatComponent
    },
    {
      path:'site-analytics', component:SiteAnalyticsComponent
    },

    {
      path:'user-profile', component:UserProfileComponent
    },

    


    {
      path:'client/:id', component:PatientProfileComponent,
      
    },
    {
      path:'client-bills/:id', component:PatientBillsProfileComponent
    },
    {
      path:'client-basic/:id', component:ClientBasicProfileComponent
    },
    {
      path:'client-profile/:id', component:ProfileInfoComponent
    },
    {
      path:'client-wallet/:id', component:ClientWalletComponent,
      
    },
    {
      path:'client-app-form/:id/:formid', component:MaFormComponent
    },
    {
      path:'client-overview/:id', component:PatientOverviewComponent
    },
    {
      path:'client-labresults/:id', component:PatientLabresultsComponent
    },
    {
      path:'client-procedures/:id', component:PatientProcedurelistComponent
    },
    {
      path:'client-prescriptions/:id', component:PatientPrescriptionsComponent
    },
    {
      path:'client-opcards/:id', component:PatientOpcardsComponent
    },
    {
      path:'client-assessment/:id/:formid', component:ProfileAssesementComponent
    },
    {
      path:'client-assessment/:id/:formid/:subid', component:ProfileAssesementComponent
    },
    {
      path:'client-goals/:id', component:PatientGoalsComponent
    },
    {
      path:'client-assessment-review/:id/:formid', component:ReviewFormComponent
    },
    {
      path:'client-assessment-review/:id/:formid/:subid', component:ReviewFormComponent
    },
    {
      path:'client-add-remarks/:id/:sdid', component:AddRemarksComponent
    },
    {
      path:'client-audio-test/:id/:testid', component:AudioTestComponent
    },
    {
      path:'client-assign/:id', component:AsesmnttActivityComponent
    },
    {
      path:'client-generate-report/:id', component:GenerateReportComponent
    },
    {
      path:'client-assessment-tools/:id', component:AssesmentToolComponent
    },
    {
      path:'client-progress/:id', component:ProgressTrackerComponent
    },

        {
          path:'schedule-flex', component:ScheduleFlexComponent
        },
        /*{

          path:'schedule-individual/:id', component:IndividualScheduleComponent
        },
        {
          path:'schedule-individual', component:IndividualScheduleComponent
        },*/
        {
          path:'enquiry', component:EnquiryComponent
        },
        {
          path:'billing', component:BillingComponent
        },
        {
          path:'billing/:mode', component:BillingComponent
        },
        /*{
          path:'bill-heads', component:BillHeadsComponent
        },*/
        
        {
          path:'generate-bill/:mode/:id', component:GenerateBillComponent
        },
        {
          path:'generate-bill/:mode/:id/:appointmentId', component:GenerateBillComponent
        },
        {
          path:'generate-bill/:mode', component:GenerateBillComponent
        },
        {
          path:'generate-bill/:mode/:id', component:GenerateBillComponent
        },
        {
          path:'generate-bill/:mode/:id/:appointmentId', component:GenerateBillComponent
        },

        {
          path:'patients/:mode', component:PatientsNewComponent,
          /*children:[
            {
              path:'branch', component:PatientsBranchComponent
            },
            {
              path:'referred', component:PatientsReferredComponent
            },
          ]*/
        },
        {
          path:'patients-list', component:PatientsListComponent,
          children:[
            {
              path:'branch', component:PatientsBranchComponent
            },
            {
              path:'referred', component:PatientsReferredComponent
            },
          ]
          
        }, 
        {
          path:'receptionHome', component:ReceptionistHomeComponent
        },
        {
          path:'notification', component:NotificationComponent
        },
        {
          path:'profile', component:ProfileComponent
        },
        {
          path:"users", redirectTo:"admin/users", pathMatch:"full"
        },
        {
          path:"branches", redirectTo:"admin/branches", pathMatch:"full"
        },
        {
          path:"assets", redirectTo:"admin/assets", pathMatch:"full"
        },
        {
          path:"inventory", redirectTo:"admin/inventory", pathMatch:"full"
        },
        {
          path:"sales", redirectTo:"admin/sales", pathMatch:"full"
        },
        {
          path:"purchase-orders", redirectTo:"admin/purchase-orders", pathMatch:"full"
        },
        {
          path:"purchase-orders/:id", redirectTo:"admin/purchase-orders/:id", pathMatch:"full"
        },
        {
          path:"purchase-history", redirectTo:"admin/purchase-history", pathMatch:"full"
        },
        {
          path:"feedbacks", redirectTo:"admin/feedbacks", pathMatch:"full"
        },
        {
          path:"events", redirectTo:"admin/events", pathMatch:"full"
        },
        {
          path:"super-admin", redirectTo:"sa/index", pathMatch:"full"
        },
        {
          path:"service", redirectTo:"admin/service", pathMatch:"full"
        },
        {
          path:"bill-heads", redirectTo:"admin/bill-heads", pathMatch:"full"
        },
        {
          path:"transfer-log", redirectTo:"admin/transfer-log", pathMatch:"full"
        },
        {
          path:"settings", redirectTo:"admin/settings", pathMatch:"full"
        }, 
        {
          path:"recent-updates", redirectTo:"consultant/recent-updates", pathMatch:"full"
        },
        {
          path:"tags", redirectTo:"consultant/tags", pathMatch:"full"
        },
        {
          path:'resource', component:ResourceComponent,
        },

        {
          path: 'analytics', redirectTo:'site-analytics'
        },

        

      ],
    },
    {
      path:'login', component:LoginComponent
    },
    
    {
      path:'client/:id', component:PatientProfileComponent
    },
    {
      path:'client-overview/:id', component:PatientOverviewComponent
    },
    {
      path:'client-assessment/:id/:formid', component:ProfileAssesementComponent
    },
    {
      path:'client-assessment/:id/:formid/:subid', component:ProfileAssesementComponent
    },
    {
      path:'client-goals/:id', component:PatientGoalsComponent
    },
    {
      path:'client-assessment-review/:id/:formid', component:ReviewFormComponent
    },
    {
      path:'client-assessment-review/:id/:formid/:subid', component:ReviewFormComponent
    },
    {
      path:'client-add-remarks/:id/:sdid', component:AddRemarksComponent
    },
    {
      path:'client-assign/:id', component:AsesmnttActivityComponent
    },
    {
      path:'client-generate-report/:id', component:GenerateReportComponent
    },
    {
      path:'client-assessment-tools/:id', component:AssesmentToolComponent
    },
    {
      path:'client-progress/:id', component:ProgressTrackerComponent
    },
 
    
    /*{
      path:'signup', component:SignupComponent
    },*/
    {
      path:'', component:LoginComponent
    },
    {
      path:'viewer/:id', component:ViewerComponent
    },
    /*{
      path:'patient-feedback/:hash/:id', component:PatientFeedbackComponent
    },*/
    {
      path:'video-call/:id', component:ChatWindowComponent
    },
    {
      path:'video-call/:id/:sessionId', component:ChatWindowComponent
    },
    
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
